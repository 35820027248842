import { useHistory, useLocation } from 'react-router';
import { useEffect } from 'react';
import { SpinnerIcon } from '@kindlyhuman/component-library';
import { ROUTE_PATH } from '../../routes/route-paths';
import useAuth from '../../hooks/useAuth';

const AuthenticatePage = () => {
  const location = useLocation();
  const navigate = useHistory();
  const { authenticateWithToken } = useAuth();
  const queryParams = new URLSearchParams(location.search);

  useEffect(() => {
    const token = queryParams.get('token');
    const userId = queryParams.get('user_id');
    if (!token || !userId) {
      console.log({
        msg: 'Invalid token or userId. Was not able to authenticate.',
        token: token ? `${token?.slice(0, 3)}***${token?.slice(-3)}` : null,
        userId,
      });
      return;
    }
    authenticateWithToken.mutate(
      {
        token: token,
        userId: parseInt(userId),
      },
      {
        onError: () => {
          sessionStorage.setItem('partial_member_id', JSON.stringify(userId));
          navigate.push(ROUTE_PATH.VERIFICATION);
        },
      },
    );
  }, []);

  return (
    <div className="w-full h-full gap-6 flex items-center justify-center">
      <SpinnerIcon />
      <p>Signing In</p>
    </div>
  );
};

export default AuthenticatePage;
