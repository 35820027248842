import React from 'react';
import { useMediaQuery } from '../../hooks/useMediaQuery';

export const BackgroundStripe: React.FC = () => {
  const dfMdMedia = useMediaQuery('md');

  return (
    <>
      {dfMdMedia && (
        <div className="w-[5000px] h-[300px] fixed bottom-48 rotate-[-3.72deg] -ml-10 bg-[#005eff]/10 -z-10"></div>
      )}
    </>
  );
};
