import { OptionRadio } from '@kindlyhuman/component-library';
import { useFormContext, Controller } from 'react-hook-form';
import { Subheader } from '..';
import { PostCall } from '../../../../hooks/usePostCall';
import { twMerge } from 'tailwind-merge';

const describeOptions = ['Amazing', 'Great', 'Good', 'Ok', 'Not Great'];

export interface DescribeStepProps {
  postCall?: PostCall;
  className?: string;
}

export const DescribeStep: React.FC<DescribeStepProps> = ({ postCall, className }) => {
  const { control } = useFormContext();

  return (
    <div className={twMerge('space-y-5', className)}>
      <Subheader
        className="px-4 md:px-0"
        question={
          <>
            How would you describe your call with{' '}
            <span className="font-bold">{postCall?.call.listener_role.user.display_name}</span>?
          </>
        }
        postCall={postCall}
      />
      <div className="bg-white">
        {describeOptions?.map((item: string) => (
          <Controller
            key={item}
            control={control}
            name="description"
            render={({ field: { value, onChange } }) => (
              <label htmlFor={item} className="cursor-pointer">
                <OptionRadio
                  id={item}
                  label={item}
                  value={item}
                  checked={value === item}
                  onChange={() => onChange(item)}
                  className="md:border-0 md:!bg-transparent md:px-0"
                />
              </label>
            )}
          />
        ))}
      </div>
    </div>
  );
};
