import { twMerge } from 'tailwind-merge';

interface TagPillProps {
  name: string;
  className?: string;
}

export const TagPill: React.FC<TagPillProps> = ({ name, className }) => {
  return (
    <div
      className={twMerge(
        'text-nowrap h-5 text-xs font-texta antialiased leading-normal bg-white px-2 rounded-md',
        className,
      )}
    >
      {name}
    </div>
  );
};
