import React from 'react';
import { ROUTE_PATH } from '../../../routes/route-paths';

import TileContainer from '../../../components/mwa-3.5-redesign/tile-container';
import { PeerResponse } from '../../../hooks/usePeers';
import { UseQueryResult } from '@tanstack/react-query';
import {
  ConnectNowPeerTile,
  ConnectNowPeerTileSkeleton,
} from '../../../components/mwa-3.5-redesign/connect-now-peer-tile';
import {
  MyConnectionsPeerTile,
  MyConnectionsPeerTileSkeleton,
} from '../../../components/mwa-3.5-redesign/my-connections-peer-tile';

interface MyConnectionsProps {
  myConnections: UseQueryResult<PeerResponse, unknown>;
}

const MyConnections: React.FC<MyConnectionsProps> = ({ myConnections }) => {
  const listeners = myConnections?.data?.data.filter((peer) => peer.is_listener).slice(0, 4);
  const remainingSlots = 6 - (listeners?.length ? listeners.length : 0);
  const peers = myConnections?.data?.data.filter((peer) => !peer.is_listener).slice(0, remainingSlots);
  const combinedPeers = [...(listeners || []), ...(peers || [])];

  return (
    <TileContainer
      title={'My Connections'}
      subtitle={'Peers you have connected with before'}
      isLoading={myConnections.isLoading}
      childrenTiles={
        <>{combinedPeers?.map((peer) => <MyConnectionsPeerTile key={peer.listener_role_id} peer={peer} />)}</>
      }
      skeletonTiles={[1, 6].map((int) => (
        <MyConnectionsPeerTileSkeleton key={int} />
      ))}
      dataTestId={'my-connections-container'}
      redirectText={'See all of My Connections »'}
      redirectLink={ROUTE_PATH.MY_CONNECTIONS}
      titleSkeletons={myConnections.isLoading}
    />
  );
};

export default MyConnections;
