import { Route, Redirect, Switch } from 'react-router-dom';

// Page Imports
import {
  HomePage,
  Profile,
  MyPreferences,
  Help,
  Counseling,
  Telemed,
  PostCallSequnece,
  DependentsPage,
  AvailableListenersPage,
  PeerDetailPage,
  MyConnectionsPage,
} from '../containers';

// These could be added to the containers index
import { CallHistory } from '../containers/member-management/call-history';
import { PrivacyPolicyPage, TermsPage } from '../containers/terms-and-privacy-policy/terms-and-privacy-policy';
import AboutUsPage from '../containers/about-us/about-us';
import AccountSettingsPage from '../containers/account-settings/account-settings';
import { PaymentMethodPage } from '../containers/member-management/payment-method';
import { ResourceDetailPage } from '../containers/resources/resource-detail';
import { RecommendedPeersPage } from '../containers/recommended-peers/recommended-peers';
import { ChangePasswordPage } from '../containers/change-password/change-password';
import { MemberManagementPage } from '../containers/member-management/member-management';
import { SupportPage } from '../containers/support/support';
import { ResourcePage } from '../containers/resources/resources';
import { CrisisPage } from '../containers/resources/plus-care-details/crisis-detail';
import { VirtualUrgentPage } from '../containers/resources/plus-care-details/urgent-detail';
import { PrescriptionBenefitPage } from '../containers/resources/plus-care-details/rx-detail';
import { PediatricPage } from '../containers/resources/plus-care-details/pediatric-detail';
import { GlobalResourcePage } from '../containers/resources/global-resources';
import { ROUTE_PATH } from './route-paths';
import { publicRoutes } from './unuathenticated-router';
import useAuth from '../hooks/useAuth';
import WelcomeVideo from '../containers/onboarding/components/welcome-video';
import { AddToHome } from '../containers/onboarding/components/add-to-home';
import MyPlanPage from '../containers/my-plan/my-plan-page';

const callerRoleRoutes = [
  {
    path: ROUTE_PATH.HOME,
    component: HomePage,
  },
  {
    path: ROUTE_PATH.WELCOME_VIDEO,
    component: WelcomeVideo,
  },
  {
    path: ROUTE_PATH.ADD_TO_HOME,
    component: AddToHome,
  },
  {
    path: ROUTE_PATH.PEER_DETAILS,
    component: PeerDetailPage,
  },
  {
    path: ROUTE_PATH.AVAILABLE_LISTENERS,
    component: AvailableListenersPage,
  },
  {
    path: ROUTE_PATH.ACCOUNT_SETTINGS,
    component: AccountSettingsPage,
  },
  {
    path: ROUTE_PATH.MY_CONNECTIONS,
    component: MyConnectionsPage,
  },
  {
    path: ROUTE_PATH.RECOMMENDED_PEERS,
    component: RecommendedPeersPage,
  },
  {
    path: ROUTE_PATH.PROFILE,
    component: Profile,
  },
  {
    path: ROUTE_PATH.MY_PREFERENCES,
    component: MyPreferences,
  },
  {
    path: ROUTE_PATH.CHANGE_PASSWORD,
    component: ChangePasswordPage,
  },
  {
    path: ROUTE_PATH.MEMBERSHIP_MANAGEMENT,
    component: MemberManagementPage,
  },
  {
    path: ROUTE_PATH.RESOURCES,
    component: ResourcePage,
  },
  {
    path: ROUTE_PATH.GLOBAL_RESOURCES,
    component: GlobalResourcePage,
  },
  {
    path: `${ROUTE_PATH.RESOURCE}/:name`,
    component: ResourceDetailPage,
  },
  {
    path: ROUTE_PATH.HELP,
    component: Help,
  },
  {
    path: ROUTE_PATH.SUPPORT,
    component: SupportPage,
  },
  {
    path: ROUTE_PATH.ABOUT,
    component: AboutUsPage,
  },
  {
    path: ROUTE_PATH.TERMS_AND_SERVICE,
    component: TermsPage,
  },
  {
    path: ROUTE_PATH.PRIVACY_POLICY,
    component: PrivacyPolicyPage,
  },
  {
    path: ROUTE_PATH.RETURN,
    component: HomePage,
  },
  {
    path: ROUTE_PATH.COUNSELING,
    component: Counseling,
  },
  {
    path: ROUTE_PATH.TELEMED,
    component: Telemed,
  },
  {
    path: ROUTE_PATH.URGENT,
    component: VirtualUrgentPage,
  },
  {
    path: ROUTE_PATH.CRISIS_LITE,
    component: CrisisPage,
  },
  {
    path: ROUTE_PATH.PRESCRIPTION,
    component: PrescriptionBenefitPage,
  },
  {
    path: ROUTE_PATH.PLAN,
    component: MyPlanPage,
  },
  {
    path: ROUTE_PATH.PEDIATRIC,
    component: PediatricPage,
  },
  {
    path: ROUTE_PATH.POSTCALLSEQUENCE,
    component: PostCallSequnece,
  },
  {
    path: ROUTE_PATH.CALL_HISTORY,
    component: CallHistory,
  },
];

export const ActiveCallerRouter = () => {
  const { user } = useAuth();
  return (
    <Switch>
      {callerRoleRoutes.map(({ path, component }) => (
        <Route exact path={path} component={component} />
      ))}
      {publicRoutes.map(({ path, component }) => (
        <Route exact path={path} component={component} />
      ))}
      {user?.caller_role?.active_subscription?.package.dependents_configuration.enabled && (
        <Route exact path={ROUTE_PATH.DEPENDENTS} component={DependentsPage} />
      )}
      {!user?.onUnlimitedPlan && <Route exact path={ROUTE_PATH.PAYMENT} component={PaymentMethodPage} />}
      <Route exact render={() => <Redirect to={ROUTE_PATH.HOME} />} />
    </Switch>
  );
};

export const NonActiveCallerRouter = () => {
  return (
    <Switch>
      <Route exact path="*" render={() => <Redirect to={ROUTE_PATH.WELCOME_PAGE} />} />
    </Switch>
  );
};
