import { useLayoutEffect, useMemo } from 'react';
import { morphiiContext } from '../../app';
import { Spinner } from '../common';

export const morphiiWidgetId = '6542770446598025216';
export const morphiiDivId = 'morphii-widget';

export type MorphiiProps = {
  hasMorphii: boolean;
  setHasMorphii: React.Dispatch<React.SetStateAction<boolean>>;
};

export const MorphiiWidget: React.FunctionComponent<MorphiiProps> = ({ setHasMorphii, hasMorphii }): JSX.Element => {
  // Documentation: https://docs.morphii.com/widget_documentation_2_0.html
  // Define the morphii ids that will be used in the widgets.
  // Publicly available: https://docs.morphii.com/morphii_list.html
  var morphiiIds = useMemo(() => [{ id: morphiiWidgetId }], []);

  // Define the widget options.
  const widgetOptions = useMemo(() => {
    return {
      div_id: morphiiDivId,
      morphii: {
        ids: morphiiIds,
        show_name: false, // Set to `false` to not display morphii names.
        wrap_name: true,
      },
      target: {
        id: morphiiDivId,
        type: 'question',
      },
      comment: {
        show: false, // Set to `true` to see comment field.
        required: false,
      },
      slider: {
        initial_intensity: 0.5,
        show_animation: true,
        anchor_labels: {
          show: false,
        },
      },
      selection: {
        required: true, // Set to `false` to not require selection.
      },
      instructions: {
        show: false,
      },
      options: {
        stage: 'test',
      },
    };
  }, [morphiiIds]);

  const collectionOptions = useMemo(() => {
    return {
      client_key: 'fddb7ab5-1d11-4fc9-907e-66098ed66242',
      account_id: '45981204',
      project: {
        id: 'widget-sample-loc',
        description: 'Sample widget code for LOC.',
      },
      application: {
        name: 'sample-loc',
        description: 'Sample demo for LOC.',
        version: '1.0',
      },
      user: {
        id: 'user-id',
      },
      callbacks: {
        error: (error: any) => {
          console.error('Error callback: ' + JSON.stringify(error, null, 2));
        },
        selection_change: (data: any) => {
          setHasMorphii(data.selection_required_valid);
        },
      },
    };
  }, [setHasMorphii]);

  useLayoutEffect(() => {
    morphiiContext.init(collectionOptions, (error: any, valid: any) => {
      if (valid === true) {
        const option = widgetOptions;
        morphiiContext.add(option, (error: any, results: any) => {
          if (error) {
            console.error('Collection add error: ' + JSON.stringify(error, null, 2));
          }
        });
      } else {
        console.error('Init error: ' + JSON.stringify(error, null, 2));
      }
    });
  }, [collectionOptions, widgetOptions]);

  return (
    <div className={hasMorphii ? 'pb-5' : ''}>
      {morphiiContext ? <div id={morphiiDivId} className="schedule-morphii mx-5" /> : <Spinner />}
    </div>
  );
};

export interface MorphiiResult {
  submitted: boolean;
  widget_id: string;
  div_id: string;
  target_id: string;
  reaction_id: string;
  reaction_record: ReactionRecord;
}

export interface ReactionRecord {
  id: string;
  created_date_utc: number;
  changed_date_utc: number;
  version: string;
  application: Application;
  client: Client;
  timestamp: Timestamp;
  account: Account;
  morphii: Morphii;
  target: Target;
  state: State;
  timestamp_server_utc: string;
  user: User;
  project: Project;
}

export interface Application {
  name: string;
  description: string;
  version: string;
}

export interface Client {
  color_depth: number;
  screen_width: number;
  screen_height: number;
  screen_avail_width: number;
  screen_avail_height: number;
  browser_platform: string;
  browser_product: string;
  browser_language: string;
  session: string;
  referer: string;
  agent: string;
  ip: string;
}

export interface Timestamp {
  utc: string;
  offset: string;
}

export interface Account {
  id: string;
  name: string;
}

export interface Morphii {
  id: string;
  name: string;
  intensity: number;
  part_name: string;
  parts: string[];
  display_name: string;
  display_name_en: string;
  validated_label_id: number;
  weight: number;
  intensity_forced: boolean;
  choices: Choice[];
  urls: Urls;
}

export interface Choice {
  id: string;
  name: string;
  display_name: string;
  display_name_en: string;
  validated_label_id: number;
  weight: number;
}

export interface Urls {
  png: string;
  jpg: string;
}

export interface Target {
  id: string;
  type: string;
  metadata: Metadaum[];
}

export interface Metadaum {
  key: string;
  value: string;
}

export interface State {
  deleted: boolean;
  stage: string;
}

export interface User {
  id: string;
}

export interface Project {
  id: string;
  description: string;
}
