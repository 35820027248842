import { FunctionComponent } from 'react';

import { SpinnerIcon } from '@kindlyhuman/component-library';
import { KindlyHumanTextBlue } from '../../assets';

export const Spinner: FunctionComponent = (): JSX.Element => (
  <div className="flex items-center justify-center h-full w-full fixed bg-white z-200 top-0 bottom-0">
    <div className="flex flex-col gap-y-2 items-center">
      <img src={KindlyHumanTextBlue} alt="" />
      <SpinnerIcon />
    </div>
  </div>
);
