import { useMutation } from '@tanstack/react-query';
import { axiosPost } from '../api/axios-handler';

export const useProfilePageView = () => {
  return useMutation(({ listenerRoleId }: { listenerRoleId: number }) => {
    return axiosPost(`/profile_view/`, {
      listener_role_id: listenerRoleId,
    });
  });
};
