import { UnsavedChangesModal } from '../../../UnsavedChangesModal';
import ProfileForm, { ProfileFormProps } from '../../../profile_form';

import { TabHeader } from '../tab_header';

export interface ProfileTabProps extends ProfileFormProps {
  showUnsavedChangesModal?: boolean;
  onCloseUnsavedChangesModal?: () => void;
  onDiscardUnsavedChangesModal?: () => void;
}

export const ProfileTab = ({
  showUnsavedChangesModal,
  onCloseUnsavedChangesModal,
  onDiscardUnsavedChangesModal,
  ...props
}: ProfileTabProps) => (
  <ProfileForm
    withLoader
    topActionButton={({ isDirty, isSubmitting, isValid }) => (
      <>
        <TabHeader
          header="Account settings"
          buttonText={isSubmitting ? 'Saving ...' : 'Save'}
          isButtonDisabled={!isDirty || isSubmitting}
        />
        {showUnsavedChangesModal && onCloseUnsavedChangesModal && (
          <UnsavedChangesModal
            open={showUnsavedChangesModal}
            onClose={onCloseUnsavedChangesModal}
            onDiscard={onDiscardUnsavedChangesModal}
            savingInProgress={isSubmitting}
          />
        )}
      </>
    )}
    {...props}
  />
);
