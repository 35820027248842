import { UseQueryOptions, useQuery, QueryKey, useMutation, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { axiosGet, axiosPut } from '../api/axios-handler';
import { MorphiiResult } from '../components/morphii/morphii-widget';
import { TagStatus } from './useCalls';

export interface PostCall {
  begin_call_mood: number;
  call: Call;
  call_id: number;
  call_reported: boolean;
  completed_at: string;
  counseling_recommended: boolean;
  end_call_mood: number;
  id: number;
  package_id: any;
  resources: any[];
  telemed_recommended: boolean;
  topic_tags: TopicTag[];
}

interface PostCallListenerRole {
  id: number;
  user: {
    display_name: string;
  };
}

interface Call {
  listener_role: PostCallListenerRole;
  call_rate: number;
  caller_role: CallerRole;
  caller_role_id: number;
  created_at: string;
  duration_timer_began_at: any;
  ended_at: string;
  id: number;
  listener_earned_amount: number;
  listener_paid_amount: number;
  listener_role_id: number;
  listener_state_id: any;
  logged_duration: number;
  package_id: any;
  recording_path: any;
  state_id: any;
  termination_disposition: string;
  tier_id: number;
}

interface CallerRole {
  id: number;
  listener_preferences: ListenerPreferences;
  user: User;
}

interface ListenerPreferences {
  age_range: AgeRange;
  all_topics: boolean;
  genders: Genders;
  languages: string[];
  preferred_language: string;
  topic_tags: any[];
  topics: any[];
}

interface AgeRange {
  from: number;
  to: number;
}

interface Genders {
  female: boolean;
  male: boolean;
  other: boolean;
}

interface User {
  first_name: string;
  id: number;
  pronoun: any;
}

interface TopicTag {
  created_at: string;
  id: number;
  status: TagStatus;
  listener_audio_id: any;
  postcall_metadata_id: number;
  sort_order: number;
  tag: Tag;
  tag_id: number;
  user_id: any;
}

interface Tag {
  id: number;
  is_background: boolean;
  is_default: boolean;
  is_required: boolean;
  is_visible: boolean;
  name: string;
  sort_order: number;
  tag_type: string;
}

export interface PostCallListItem {
  id: number;
  call: PostCall_Call;
}

interface PostCallListener {
  available_now: boolean;
  profile_photo_square_file_url: string;
  user: {
    display_name: string;
  };
}

interface PostCallCallRequest {
  tag_group_ids: number[];
}

interface PostCall_Call {
  id: number;
  created_at: string;
  ended_at: string;
  listener_role: PostCallListener;
  logged_duration: number;
  request: PostCallCallRequest;
  termination_disposition: string;
}
interface PostCallListResponse {
  count: boolean;
  data: PostCallListItem[];
}

const getPostCalls = async (callerId: number, incompleteOnly: boolean = false): Promise<PostCallListItem[]> => {
  return await axiosGet(`/postcall_metadata/caller/${callerId}`, {
    incomplete_only: incompleteOnly,
  }).then((response: AxiosResponse<PostCallListResponse>) => response.data.data);
};

export const usePostCalls = (
  callerId?: number,
  incompleteOnly: boolean = false,
  options?: Omit<
    UseQueryOptions<PostCallListItem[], unknown, PostCallListItem[], QueryKey>,
    'initialData' | 'queryFn' | 'queryKey'
  > & { initialData?: (() => undefined) | undefined },
) => {
  return useQuery<PostCallListItem[]>(
    ['postCallList', callerId, incompleteOnly],
    () => getPostCalls(callerId!, incompleteOnly),
    {
      enabled: Boolean(callerId),
      ...options,
    },
  );
};

const getPostCall = async (postCallId: number): Promise<PostCall> => {
  return await axiosGet(`/postcall_metadata/${postCallId}`, {
    include_timezone_offset: true,
  }).then((response) => response.data);
};

export const usePostCall = (
  postCallId: number,
  options?: Omit<UseQueryOptions<PostCall, unknown, PostCall, QueryKey>, 'initialData' | 'queryFn' | 'queryKey'> & {
    initialData?: (() => undefined) | undefined;
  },
) =>
  useQuery<PostCall>(['postCall', postCallId], () => getPostCall(postCallId), {
    // 1 hour
    staleTime: 1000 * 60 * 60,
    ...options,
  });

export interface PostCallData {
  connectAgain?: boolean;
  description?: string;
  feedback?: string;
  morphii?: MorphiiResult;
}

interface PostCallMutationPayload {
  postCallId: number;
  payload: PostCallData;
}

export const useUpdatePostCall = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ postCallId, payload: { connectAgain, description, feedback, morphii } }: PostCallMutationPayload) => {
      return axiosPut(`/postcall_metadata/${postCallId}`, {
        caller_connect: connectAgain,
        caller_description: description,
        caller_feedback: feedback,
        caller_morphii: morphii,
        caller_completed_at: new Date().toISOString(),
      });
    },
    {
      onMutate: ({ postCallId }: { postCallId: number }) => {
        queryClient.setQueryData(['postCallList'], (old: PostCallListItem[] | undefined) =>
          old?.filter((postCall) => postCall.id !== postCallId),
        );
      },
      onSettled: () => {
        queryClient.invalidateQueries(['postCallList']);
      },
    },
  );
};
