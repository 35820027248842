import { useState } from 'react';
import { Button, EyeIcon, MobileHeader, Input, LockIcon } from '@kindlyhuman/component-library';
import { useRedeemPasswordRequest } from '../../hooks/useUser';
import { WelcomeSlogan } from '../../components/common/WelcomeSlogan';
import Toast from '../../components/common/PopUpMessage';
import { useHistory, useLocation } from 'react-router';
import { ROUTE_PATH } from '../../routes/route-paths';

const ResetPasswordPage = (): JSX.Element => {
  const navigate = useHistory();
  const location = useLocation();
  const redeemPasswordRequest = useRedeemPasswordRequest();
  const queryParams = new URLSearchParams(location.search);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const [newPassword, setNewPassword] = useState<any>('');
  const [confirmPassword, setConfirmPassword] = useState<any>();

  const validatePassword = () => {
    const passwordRegex = /^(?=.*\d).{8,}$/;
    if (!passwordRegex.test(newPassword)) {
      setPasswordError('Password must be at least 8 characters long and contain a number');
    } else {
      setPasswordError('');
    }
  };

  const validateConfirmPassword = () => {
    if (newPassword !== confirmPassword) {
      setConfirmPasswordError('Passwords must match');
    } else {
      setConfirmPasswordError('');
    }
  };

  const handlePasswordReset = (): void => {
    const resetToken = queryParams.get('token');
    const input = {
      token: resetToken,
      password: newPassword,
    };
    redeemPasswordRequest.mutate(input, {
      onSuccess: () => {
        Toast.success('Your password has been successfully changed! Please sign in with a new password');
        navigate.push(ROUTE_PATH.LOGIN);
      },
      onError: (error: any) => {
        const errorCode = error?.response?.data?.error_code;
        const errorMessage = error?.response?.data?.description;

        if (errorCode === 'NOT_FOUND') {
          Toast.error(errorMessage);
        } else if (errorMessage) {
          setPasswordError(errorMessage);
        }
      },
    });
  };

  return (
    <div className="min-h-screen bg-[#F6F6F6]">
      <MobileHeader className="md:hidden" />
      <div className="grid w-screen grid-cols-1 md:grid-cols-2 md:min-h-screen">
        <WelcomeSlogan className="hidden md:block" />
        <div className="p-5 md:px-15 lg:px-30 md:flex flex-col items-center justify-center">
          <div className="flex mt-8 flex-col self-stretch gap-1">
            <h2 className="text-gray-900 text-2xl not-italic font-bold leading-8 font-manrope">Reset your Password</h2>
            <p className="text-gray-900 text-base not-italic font-normal leading-6">Enter your new password below</p>
          </div>
          <div className="py-8 flex flex-col gap-4 w-full">
            <Input
              id="newPassword"
              label={'New Password'}
              value={newPassword}
              placeholder="Enter your password"
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
              onBlur={validatePassword}
              error={passwordError !== ''}
              type={showPassword ? 'text' : 'password'}
              helperText={passwordError}
              endIcon={<EyeIcon />}
              startIcon={<LockIcon />}
              onIconClick={() => setShowPassword(!showPassword)}
            />
            <Input
              id="confirmPassword"
              label={'Confirm new password'}
              value={confirmPassword}
              placeholder="Re-enter your password"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              onBlur={validateConfirmPassword}
              error={confirmPasswordError !== ''}
              type={showConfirmPassword ? 'text' : 'password'}
              endIcon={<EyeIcon />}
              startIcon={<LockIcon />}
              onIconClick={() => setShowConfirmPassword(!showConfirmPassword)}
              helperText={confirmPasswordError}
            />
          </div>
          <Button
            variant="primary"
            className="w-full"
            onClick={handlePasswordReset}
            disabled={redeemPasswordRequest.isLoading || passwordError !== '' || confirmPasswordError !== ''}
            loading={redeemPasswordRequest.isLoading}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordPage;
