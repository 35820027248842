import { useEffect, useMemo, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { useResourceByID } from '../../hooks/useResources';
import { MobileMenuHeader, DesktopMenuHeader } from '../../components/MenuHeader';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import { Spinner } from '../../components/common/spinner';
import { Button } from '@kindlyhuman/component-library';
import { TagPill } from '../../components/mwa-3.5-redesign/tag-pill';
import NotFound404 from '../not-found/not-found-404';
import { ROUTE_PATH } from '../../routes/route-paths';
import { PartnerResourceConfig } from './partner-resource-config';
import { BackgroundStripe } from '../../components/mwa-3.5-redesign/bg-stripe';
import useAuth from '../../hooks/useAuth';
import { ProviderName } from '../../hooks/useExternalProviderPlans';
import { useRollbar } from '@rollbar/react';
import { useProviderDetails } from './resource-components/useProviderDetails';
import { useHubspotEventMutation } from '../../hooks/useEvent';

export const ResourceDetailPage = () => {
  const navigate = useHistory();
  const { user } = useAuth();
  const { pathname } = useLocation();
  const resourceID = useMemo(() => parseInt(pathname.match(/\/resource\/(\d+)_.*/)?.[1] ?? ''), [pathname]);
  const { data: resource, isLoading: isResourceLoading } = useResourceByID(resourceID);

  const client = user?.caller_role.active_subscription?.package?.client;

  const bodyComponent = useMemo(() => {
    if (isResourceLoading) {
      return <Spinner />;
    }
    if (!resource) {
      return <NotFound404 />;
    }
    return (
      <ResourceDetailView
        phoneNumber={resource.phone_number}
        name={resource.name}
        url={resource.url}
        urlDescription={resource.url_description}
        ctaButtonText="Visit Online"
        imageUrl={resource.image_url}
        tagNames={resource.tag_names}
        description={resource.long_description || resource.description}
        clientLogoUrl={resource.client_id ? client?.comms_logo_file_url : undefined}
        isGlobal={!resource.client_id}
      />
    );
  }, [isResourceLoading, resource, navigate]);

  return (
    <div className="md:bg-neutral-700 md:bg-opacity-5 h-screen md:pb-8 flex flex-col">
      <DesktopMenuHeader />
      <MobileMenuHeader onBack={() => navigate.goBack()} />
      {bodyComponent}
      <BackgroundStripe />
    </div>
  );
};

interface ResourceDetailProps {
  name: string;
  url?: string;
  urlDescription: string;
  ctaButtonText: string;
  imageUrl: string;
  tagNames?: string[] | null;
  description?: string | JSX.Element;
  clientLogoUrl?: string;
  ctaButtonDisabled?: boolean;
  ctaButtonContent?: JSX.Element;
  partnerResourceConfig?: PartnerResourceConfig;
  ssoLinkLoading?: boolean;
  phoneNumber?: string;
  callButtonText?: string;
  isGlobal?: boolean;
  providerName?: ProviderName;
}

export const ResourceDetailView: React.FC<ResourceDetailProps> = ({
  name,
  url,
  urlDescription,
  ctaButtonText,
  imageUrl,
  tagNames,
  description,
  clientLogoUrl,
  ctaButtonDisabled,
  partnerResourceConfig,
  ssoLinkLoading,
  phoneNumber,
  callButtonText = 'Call',
  isGlobal,
  providerName,
}) => {
  const { user } = useAuth();
  const hubspotMutation = useHubspotEventMutation();
  const desktop = useMediaQuery('md');
  const rollbar = useRollbar();
  const urlParams = new URLSearchParams(url?.split('?')[1]);
  // TODO: Clean this up once we refactor how we handle errors in the backend
  const { ssoError } = useProviderDetails();
  const urlError = ssoError || (providerName && !url);
  const errorMessage = urlParams.get('error_message') ? decodeURIComponent(urlParams.get('error_message')!) : undefined;
  const errorMessageHasFired = useRef<boolean>(false);

  if ((errorMessage || urlError) && !errorMessageHasFired.current) {
    // Unfortunately, we have to use a ref to prevent this from firing twice
    errorMessageHasFired.current = true;
    rollbar.error('Partner Access Error', { providerName, errorMessage });
  }

  const displayPhoneNumber =
    !partnerResourceConfig?.needsAddress && !partnerResourceConfig?.waitForProcessingDate && !errorMessage && !urlError;

  const buttonClassNameOverride =
    'grow w-full text-[#2ee5da] hover:bg-[#081d40] hover:bg-opacity-70 bg-[#081d40] border-[#240089]';

  const handleHubspotCallButton = () => {
    if (providerName) {
      hubspotMutation.mutate({
        event: 'click_provider_call_button',
        properties: {
          email_address: user?.email_address!,
          package_code: user?.caller_role.active_subscription?.package.code!,
          provider_name: providerName,
          provider_plan_name: name,
        },
      });
    } else {
      hubspotMutation.mutate({
        event: isGlobal ? 'click_global_resource_call_button' : 'click_client_resource_call_button',
        properties: {
          email_address: user?.email_address!,
          package_code: user?.caller_role.active_subscription?.package.code!,
          resource_name: name,
        },
      });
    }
  };

  const handleHubspotLinkButton = () => {
    if (providerName) {
      hubspotMutation.mutate({
        event: 'click_provider_link',
        properties: {
          email_address: user?.email_address!,
          package_code: user?.caller_role.active_subscription?.package.code!,
          provider_name: providerName,
          provider_plan_name: name,
        },
      });
    } else {
      hubspotMutation.mutate({
        event: isGlobal ? 'click_global_resource_link' : 'click_client_resource_link',
        properties: {
          email_address: user?.email_address!,
          package_code: user?.caller_role.active_subscription?.package.code!,
          resource_name: name,
        },
      });
    }
  };

  const externalProviderCtaContent = (
    partnerResourceConfig: PartnerResourceConfig,
    providerName: string,
    ssoLinkLoading?: boolean,
    ssoLinkUrl?: string,
    errorMessage?: string,
  ) => {
    if (partnerResourceConfig.needsAddress) {
      return (
        <div className="flex flex-col items-center md:items-end">
          <div className="text-gray-800 text-sm font-medium leading-tight">
            We need your home address in order to activate this service.
          </div>
          <div className="mt-3">
            <Link to={ROUTE_PATH.PROFILE}>
              <Button slim variant="secondary">
                Add Address
              </Button>
            </Link>
          </div>
        </div>
      );
    }

    if (partnerResourceConfig.waitForProcessingDate) {
      return (
        <div className="text-gray-800 text-base font-medium leading-tight">
          You will have access to {name} beginning on {partnerResourceConfig.processingDateString}
        </div>
      );
    }

    if (errorMessage || urlError) {
      if (providerName === 'opyn') {
        return (
          <div className="text-gray-800 text-base font-medium leading-tight">
            Your access to {name} isn't active yet — please try again tomorrow.
          </div>
        );
      } else {
        return (
          <div className="text-gray-800 text-base font-medium leading-tight">
            Your access to {name} isn't active — please contact your plan administrator.
          </div>
        );
      }
    }

    return (
      <>
        <a
          className="w-full"
          href={ssoLinkUrl}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleHubspotLinkButton}
        >
          <Button className={buttonClassNameOverride} variant="primary" disabled={ssoLinkLoading}>
            {ssoLinkLoading ? 'Loading...' : ctaButtonText}
          </Button>
        </a>
      </>
    );
  };

  // this handles relative resource URLs like `google.com`
  const validPath = url?.includes('.')
    ? url.startsWith('http://') || url.startsWith('https://')
      ? url
      : `https://${url}`
    : url;

  const defaultCtaContent = (
    <>
      {' '}
      {url && (
        <a
          className="w-full"
          href={validPath}
          target="_blank"
          rel="noreferrer"
          aria-label={urlDescription}
          onClick={handleHubspotLinkButton}
        >
          <Button disabled={ctaButtonDisabled} className={buttonClassNameOverride} variant="primary">
            {ctaButtonText}
          </Button>
        </a>
      )}
    </>
  );

  return desktop ? (
    <div className="flex flex-col gap-3">
      {/* HEADER */}
      <div className="bg-[#005eff]/10">
        <div className="w-full p-4 flex flex-row max-w-7xl mx-auto">
          <img className="w-[154px] h-[154px] rounded-lg" src={imageUrl} />
          <div className="flex flex-col mx-4 gap-2">
            <div className="text-[#240089] text-2xl font-extrabold font-['Manrope'] leading-7">{name}</div>
            {tagNames && (
              <div className="flex flex-row flex-wrap gap-2">
                {tagNames.map((tagName) => (
                  <TagPill key={tagName} name={tagName} className="w-fit bg-[#25008a] text-white" />
                ))}
              </div>
            )}
          </div>
          <div className="flex flex-col pt-2 pr-4 ml-auto w-90">
            <div className="w-full pt-8 flex flex-row justify-center items-center">
              {partnerResourceConfig && providerName
                ? externalProviderCtaContent(partnerResourceConfig, providerName, ssoLinkLoading, url, errorMessage)
                : defaultCtaContent}
            </div>
            {phoneNumber && displayPhoneNumber && (
              <a href={`tel:${phoneNumber}`} onClick={handleHubspotCallButton}>
                <Button
                  className={`${providerName && providerName === 'recuro' ? buttonClassNameOverride : ''} w-full mt-2`}
                  variant="secondary"
                >
                  {providerName && providerName === 'recuro' ? 'Schedule Visit by Phone' : callButtonText}
                </Button>
              </a>
            )}
          </div>
        </div>
      </div>
      <div
        className={`w-full flex flex-row justify-between ${clientLogoUrl && 'md:flex-row-reverse'} max-w-7xl mx-auto`}
      >
        {/* PART OF MY PLAN */}
        {clientLogoUrl && (
          <div className="md:mr-24 mt-5 flex flex-row text-center text-[#222833] text-base font-bold font-['Manrope'] leading-[21px]">
            <img className="w-[98px] h-[49px]" src={clientLogoUrl} />
            <div className="py-3 px-4 text-nowrap">Part of My Plan</div>
          </div>
        )}
        <div className="flex flex-col gap-6 md:ml-5">
          {/* DESCRIPTION */}
          {description && (
            <div className={`py-5 pr-5 max-w-150 text-[#222833] text-base font-medium font-['Manrope'] leading-[21px]`}>
              {description}
            </div>
          )}
        </div>
      </div>
    </div>
  ) : (
    <div className="px-4 flex flex-col relative bg-white gap-3">
      <div className="mx-auto max-w-3xl flex flex-col justify-center items-center">
        {/* HEADER */}
        <div className="w-full flex flex-row my-4">
          <img className="w-[154px] h-[154px] rounded-lg" src={imageUrl} />
          <div className="flex flex-col mx-4 gap-2">
            <div className=" text-[#240089] text-2xl font-extrabold font-['Manrope'] leading-7">{name}</div>
            {tagNames && (
              <div className="flex flex-row flex-wrap gap-2">
                {tagNames.map((tagName) => (
                  <TagPill key={tagName} name={tagName} className="w-fit bg-[#25008a] text-white" />
                ))}
              </div>
            )}
          </div>
        </div>
        {/* CTA */}
        <div className="w-full  py-5 flex flex-col justify-center items-center">
          {partnerResourceConfig && providerName
            ? externalProviderCtaContent(partnerResourceConfig, providerName, ssoLinkLoading, url, errorMessage)
            : defaultCtaContent}
          {phoneNumber && displayPhoneNumber && (
            <a className="w-full" href={`tel:${phoneNumber}`} onClick={handleHubspotCallButton}>
              <Button
                className={`${providerName && providerName === 'recuro' ? buttonClassNameOverride : ''} w-full mt-2`}
                variant="secondary"
              >
                {providerName && providerName === 'recuro' ? 'Schedule Visit by Phone' : callButtonText}
              </Button>
            </a>
          )}
        </div>
        {/* PART OF MY PLAN */}
        {clientLogoUrl && (
          <div className="w-full flex flex-row text-center text-[#222833] text-base font-bold font-['Manrope'] leading-[21px]">
            <img className="w-[98px] h-[49px]" src={clientLogoUrl} />
            <div className="my-auto px-4 text-nowrap">Part of My Plan</div>
          </div>
        )}
        {/* DESCRIPTION */}
        {description && (
          <div className="w-full py-5 text-[#222833] text-base font-medium font-['Manrope'] leading-[21px]">
            {description}
          </div>
        )}
      </div>
    </div>
  );
};
