import { useState } from 'react';
import { Slide, SlideProps, Snackbar } from '@mui/material';
import ReactDOM from 'react-dom';

import { Toast as ToastComponent } from '@kindlyhuman/component-library';

const TIMEOUT = 5000;
const mainPopupContainer = document.getElementById('popup');

interface PopUpProps {
  message: string;
  properties: propertyType;
  toastContainer?: HTMLElement | null | undefined;
}

interface propertyType {
  background: string;
  headerText: string;
  variant: string;
}
export const Popup = ({ message, properties, toastContainer = mainPopupContainer }: PopUpProps) => {
  const [isOpen, setIsOpen] = useState(true);

  const removePopUp = () => {
    setIsOpen(false);
    setTimeout(() => {
      ReactDOM.render(<></>, toastContainer);
    }, 100);
  };

  return (
    <Snackbar
      key={properties.variant + message}
      open={isOpen}
      onClose={removePopUp}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      ClickAwayListenerProps={{
        mouseEvent: false,
        touchEvent: false,
      }}
      autoHideDuration={TIMEOUT}
      TransitionComponent={SlideDownTransition}
      sx={{
        top: {
          sm: 0,
          md: '2rem',
        },
        left: {
          sm: 0,
          md: '50%',
        },
        right: {
          sm: 0,
          md: 'auto',
        },
        transform: {
          sm: 'none',
          md: 'translateX(-50%)',
        },
      }}
    >
      <ToastComponent
        className="w-full md:w-auto md:rounded-lg md:min-w-150 md:p-5"
        onExit={removePopUp}
        title={properties.variant === 'success' ? 'Success!' : 'Error!'}
        description={message}
        variant={properties.variant as 'success' | 'error'}
      />
    </Snackbar>
  );
};

function SlideDownTransition(props: SlideProps) {
  return <Slide {...props} direction="down" />;
}

const ToastProperties = {
  success: {
    variant: 'success',
    background: '#63D577',
    headerText: 'Success!',
  },
  error: {
    variant: 'error',
    background: '#EF364F',
    headerText: 'Oops!',
  },
  warning: {
    variant: 'error',
    background: '#FF5D00',
    headerText: 'Oops!',
  },
};

const generatePopUp = (
  message: any,
  properties: propertyType,
  toastContainer: HTMLElement | null | undefined = mainPopupContainer,
) => {
  ReactDOM.render(<Popup message={message} properties={properties} toastContainer={toastContainer} />, toastContainer);
};

const Toast = {
  success: (
    message: any,
    headerText = ToastProperties.success.headerText,
    toastContainer: HTMLElement | null | undefined = mainPopupContainer,
  ) =>
    generatePopUp(
      message,
      {
        ...ToastProperties.success,
        headerText,
      },
      toastContainer,
    ),
  error: (
    message: any,
    headerText = ToastProperties.error.headerText,
    toastContainer: HTMLElement | null | undefined = mainPopupContainer,
  ) =>
    generatePopUp(
      message,
      {
        ...ToastProperties.error,
        headerText,
      },
      toastContainer,
    ),
  warning: (message: any, toastContainer: HTMLElement | null | undefined = mainPopupContainer) =>
    generatePopUp(message, ToastProperties.warning, toastContainer),
};

export default Toast;
