import { useHistory } from 'react-router-dom';
import { MobileMenuHeader, DesktopMenuHeader } from '../../../components/MenuHeader';
import { PLUSVirtualUrgentCare } from '../../../assets';

import { Spinner } from '../../../components/common';

import { ResourceDetailView } from '.././resource-detail';
import { BackgroundStripe } from '../../../components/mwa-3.5-redesign/bg-stripe';

import { ProviderDescription, useProviderDetails } from '../resource-components/useProviderDetails';

export const VirtualUrgentPage: React.FC = () => {
  const navigate = useHistory();
  const { clientLogoUrl, isLoading, partnerResourceConfig, phoneNumber, providerName, url, urlDescription } =
    useProviderDetails('urgent');

  const name = '+Virtual Urgent Care';
  const description = (
    <ProviderDescription
      description="Your benefit includes 24/7 access to board-certified physicians for the treatment of common medical concerns.
      Visits are available for you and your family members virtually through your phone, web, and computer. If a
      prescription is written during your visit, it is immediately sent to your preferred pharmacy for easy pick up."
      commonUses={[
        'Allergies',
        'Cold & Flu',
        'Respiratory illness',
        'Pink eye',
        'UTIs',
        'Ear problems',
        'Skin rashes',
        'And more',
      ]}
      onlineAccessText="Click the link above to schedule a visit online."
    />
  );

  if (isLoading) return <Spinner />;

  return (
    <div className="w-full min-h-screen overflow-auto bg-cover bg-neutral-700 bg-opacity-5 pb-20">
      <DesktopMenuHeader />
      <MobileMenuHeader onBack={() => navigate.goBack()} />
      <ResourceDetailView
        name={name}
        providerName={providerName}
        imageUrl={PLUSVirtualUrgentCare}
        ctaButtonText={'Connect Now'}
        url={url}
        urlDescription={urlDescription}
        phoneNumber={phoneNumber}
        tagNames={['Urgent Care']}
        description={description}
        clientLogoUrl={clientLogoUrl}
        partnerResourceConfig={partnerResourceConfig}
      />
      <BackgroundStripe />
    </div>
  );
};
