import { useEffect } from 'react';

export enum ScriptEnvironments {
  DEVELOPMENT = 'development',
  PRODUCTION = 'production',
}

const useScript = ({
  url,
  functionText,
  id,
  integrity,
  async = true,
  environments = [ScriptEnvironments.DEVELOPMENT, ScriptEnvironments.PRODUCTION],
  crossOrigin = 'strict',
  defer = true,
  addToHead = false,
  type = 'text/javascript',
}: {
  url?: string;
  functionText?: string;
  id: string;
  integrity?: any;
  async?: boolean;
  environments?: ScriptEnvironments[];
  crossOrigin?: string;
  defer?: boolean;
  addToHead?: boolean;
  type?: string | null;
}) => {
  useEffect(() => {
    // Check if the current environment is in the list of specified environments
    if (!environments.includes(process.env.NODE_ENV as ScriptEnvironments)) {
      return;
    }

    if (!url && !functionText) {
      console.error('You need to provide either a url or a functionText to a script');
    }

    const script = document.createElement('script');
    if (url) {
      script.src = url;
    }
    if (functionText) {
      script.text = functionText;
    }
    script.defer = defer;
    script.async = async;
    script.id = id;

    if (type !== null) {
      script.type = type;
    }

    if (integrity) {
      script.integrity = integrity;
    }

    if (crossOrigin) {
      script.crossOrigin = crossOrigin;
    }

    if (addToHead) {
      const head = document.head || document.getElementsByTagName('head')[0];
      head.appendChild(script);
    } else {
      document.body.appendChild(script);
    }

    return () => {
      document.body.removeChild(script);
    };
  }, [url, integrity, async, crossOrigin]);
};

export default useScript;
