import { useEffect, useRef, useState } from 'react';
import { WelcomeImg } from '../../../assets';
import { PlayerIcon } from '../../../components/common/svgs';
import { Button, ExitIcon } from '@kindlyhuman/component-library';
import { Link } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import { twMerge } from 'tailwind-merge';

declare global {
  interface Document {
    mozCancelFullScreen?: () => Promise<void>;
    msExitFullscreen?: () => Promise<void>;
    webkitExitFullscreen?: () => Promise<void>;
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
  }
}

interface VideoElement extends HTMLVideoElement {
  msRequestFullscreen?: () => void;
  mozRequestFullScreen?: () => void;
  webkitRequestFullscreen?: () => void;
  webkitEnterFullscreen?: () => void;
}

const WelcomeVideo = () => {
  const domain = process.env.REACT_APP_DOMAIN_NAME;

  const [showVideo, setShowVideo] = useState<boolean>(false);

  const videoFullScreenBoxRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
  const videoPlayerRef = useRef<VideoElement>() as React.MutableRefObject<VideoElement>;

  const playVideo = () => {
    const elem = videoPlayerRef?.current;
    const box = videoFullScreenBoxRef?.current;

    if (elem) {
      if (isMobile) {
        if (elem.requestFullscreen) {
          elem.requestFullscreen();
        } else if (elem.webkitRequestFullscreen) {
          if (!box?.hasChildNodes()) {
            box.appendChild(elem);
          }
          box.style.display = 'block';
          elem.webkitRequestFullscreen();
        } else if (elem.msRequestFullscreen) {
          elem.msRequestFullscreen();
        } else if (elem.webkitEnterFullscreen) {
          elem.webkitEnterFullscreen();
        }
      } else {
        document.body.style.overflow = 'hidden';
        setShowVideo(true);
      }

      elem.play();
    }
  };

  const videoTimeUpdate = () => {
    const currentTimeUpdate = videoPlayerRef.current.currentTime;
    const duration = videoPlayerRef.current.duration;

    if (currentTimeUpdate === duration && isMobile) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    }
  };

  const closeVideo = () => {
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    }

    if (videoPlayerRef.current) {
      videoPlayerRef.current.pause();
    }
    document.body.style.overflow = 'auto';
    setShowVideo(false);
  };

  return (
    <>
      <div ref={videoFullScreenBoxRef} />
      <div className="relative">
        <video
          ref={videoPlayerRef}
          className={twMerge('w-full rounded-md max-h-screen', !showVideo ? 'h-0 w-0 absolute -z-1' : ' mx-auto')}
          id="myvideo"
          controls
          role="button"
          playsInline
          onTimeUpdate={videoTimeUpdate}
        >
          <source src={`${domain}/video/welcome_video`} />
        </video>
        {showVideo && (
          <button className="absolute top-6 right-8 p-1 cursor-pointer bg-primary" onClick={closeVideo}>
            <ExitIcon className="w-10 h-10" />
          </button>
        )}
      </div>
      <div className="bg-[#F6F6F6] h-screen pt-14 px-7.5 w-screen">
        {!showVideo && (
          <div className="flex flex-col items-center gap-y-8">
            <div className="relative">
              <img src={WelcomeImg} alt="welcomeImage" className="w-[139.5] h-248 rounded-md" />
              <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-20 rounded-md">
                <div className="absolute bottom-20 left-5">
                  <h6 className="text-lg font-normal leading-6 text-white">Welcome to Kindly, Human</h6>
                  <h3 className="absolute font-extrabold text-2xl text-white">Hear, together.</h3>
                </div>
              </div>
              <div className="absolute inset-0 flex items-center justify-center" role="button" onClick={playVideo}>
                <div className="w-20 h-20">
                  <PlayerIcon className="text-primary w-20 h-20" />
                </div>
              </div>
            </div>
            <Link data-testid="start-hear-button" to={'/home'}>
              <Button className="w-42 mx-auto no-wrap" variant="primary">
                Start. Hear.
              </Button>
            </Link>
          </div>
        )}
      </div>
    </>
  );
};
export default WelcomeVideo;
