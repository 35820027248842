import { Button } from '@kindlyhuman/component-library';
import { WelcomeSlogan } from '../../../components/common/WelcomeSlogan';

type introProps = {
  introImg?: string;
  introMessage?: string;
  goProfileStep: () => void;
};

const Intro: React.FunctionComponent<introProps> = ({ introImg, introMessage, goProfileStep }): JSX.Element => {
  return (
    <div
      className="
        grid grid-cols-1
        md:grid-cols-2 md:h-screen md:w-screen md:fixed md:top-0 md:left-0 md:z-20
      "
    >
      <WelcomeSlogan className="hidden md:block" />
      <div className="md:bg-whiteSmoke md:h-screen">
        <div
          className="
            py-8 gap-y-8 h-full flex flex-col items-center justify-center
            xl:px-30 lg:px-15 md:px-10 overflow-auto
          "
        >
          {introImg && (
            <div className="min-h-[200px] max-h-[400px] md:max-h-full">
              <img className="rounded-xl mx-auto md:max-h-full md:max-w-full max-w-118" src={introImg} alt="Intro" />
            </div>
          )}
          {introMessage && <div className="text-center space-y-2" dangerouslySetInnerHTML={{ __html: introMessage }} />}
          <Button variant="primary" className="mx-auto" onClick={goProfileStep}>
            Get started
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Intro;
