import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { User } from '../../hooks/useUser';
import { Spinner } from '../../components/common/spinner';
import { MobileMenuHeader, DesktopMenuHeader } from '../../components/MenuHeader';
import { UserPlan, UserPlanBalance, UserPlanRenewsDate } from '../../components/common/user_plan';
import { CancelUserPlanModal } from '../../components/CancelUserPlanModal';
import useAuth from '../../hooks/useAuth';

const MyPlanPage = (): JSX.Element => {
  const navigate = useHistory();
  const { user, isLoading: userIsLoading } = useAuth();
  const [showCancelUserPlanModal, setShowCancelUserPlanModal] = useState<boolean>(false);

  const planName = user?.caller_role.active_subscription?.package.name || '';
  const groupName = user?.caller_role.active_subscription?.package.client.name || '';
  const logoImage =
    user?.caller_role.active_subscription?.package?.my_plan_file_url ||
    user?.caller_role.active_subscription?.package.client.logo_file_url ||
    '';

  return (
    <div className="min-h-screen bg-neutral-700 bg-opacity-5">
      <DesktopMenuHeader />
      <MobileMenuHeader onBack={() => navigate.goBack()} />
      {userIsLoading && <Spinner />}
      {user && <Header user={user} planName={planName} groupName={groupName} />}
      {!!logoImage && (
        <div className="px-4 py-4 flex-col justify-center items-start gap-8 flex">
          <img className="h-24" src={logoImage} alt="Group Logo" />
        </div>
      )}
      {user && (
        <div className="space-y-4">
          <UserPlan user={user} className="py-5 px-4 bg-white border-t border-b border-neutral-200" />
          {user?.user_subscription?.remaining_minutes && user?.onCappedPlan && (
            <UserPlanBalance className="py-5 px-4 bg-white border-t border-b border-neutral-200" user={user} />
          )}
          {(user?.onCappedPlan || user?.onMemberPayPlan) && (
            <UserPlanRenewsDate className="py-5 px-4 bg-white border-t border-b border-neutral-200" />
          )}
          {/* TODO: add this when you can actually cancel a plan */}
          {/* <button
            className="block mx-auto text-primary font-bold !mt-8"
            onClick={() => setShowCancelUserPlanModal(true)}
          >
            Cancel plan
          </button> */}
        </div>
      )}
      <CancelUserPlanModal open={showCancelUserPlanModal} onClose={() => setShowCancelUserPlanModal(false)} />
    </div>
  );
};

const Header: React.FC<{ user: User; planName: string; groupName: string }> = ({ user, planName, groupName }) => (
  <div className="px-4 py-4 space-y-2">
    <p className="text-gray-800 text-2xl font-bold leading-loose">My Plan</p>
    <p className="text-stone-500 text-sm font-medium leading-tight">
      You’re currently on the {planName} plan provided by {groupName}.
    </p>
  </div>
);

export default MyPlanPage;
