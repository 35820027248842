import { useQuery } from '@tanstack/react-query';
import { axiosGet, axiosGetV3 } from '../api/axios-handler';
import useAuth from './useAuth';
import { AxiosError } from 'axios';

export type ProviderName = 'recuro' | 'opyn' | 'dialcare' | 'me_md';
export type ProviderType = 'primary' | 'counseling' | 'urgent' | 'crisis' | 'crisis_lite' | 'rx' | 'pediatric';
export interface ExternalProviderPlan {
  name: string;
  provider: ProviderName;
  id: number;
  display_name: string;
  code: string;
  provider_types: ProviderType[];
}
const getExternalProviderPlan = async (): Promise<ExternalProviderPlan[]> => {
  return await axiosGetV3(`/external_provider_plans/`, {}).then((resourceResponse) => resourceResponse.data);
};

export const useExternalProviderPlan = () =>
  useQuery<ExternalProviderPlan[]>(['externalProviderPlan'], () => getExternalProviderPlan(), {
    staleTime: Infinity,
  });

export interface RedirectUrl {
  redirect_url: string;
}

const getSsoRedirect = async (userId: number, providerName: string): Promise<RedirectUrl> => {
  return await axiosGet(`/users/${userId}/sso_redirect/${providerName}`, {})
    .then((resourceResponse) => resourceResponse.data)
    .catch((err: AxiosError) => {
      // TODO: Clean this up once we refactor how we handle errors in the backend
      throw err;
    });
};

export const useSsoRedirect = (userId: number, enabled: boolean, providerType: string) => {
  return useQuery<RedirectUrl>(['ssoRedirect', userId, providerType], () => getSsoRedirect(userId, providerType), {
    enabled: enabled,
  });
};

interface ProviderPlanData {
  providerPlan: ExternalProviderPlan | null;
  providerName: ProviderName | undefined;
  providerTypes: ProviderType[] | undefined;
  isFetching: boolean;
  isLoading: boolean;
}

export const useProviderPlan = (): ProviderPlanData => {
  const { user } = useAuth();
  const { data: providerPlans, isFetching, isLoading } = useExternalProviderPlan();

  const providerPlan =
    providerPlans?.find((plan) => plan.id === user?.caller_role.active_subscription?.package.external_plan_id) ?? null;
  const providerName = providerPlan?.provider;
  const providerTypes = providerPlan?.provider_types;

  return {
    providerPlan: providerPlan,
    providerName: providerName,
    providerTypes: providerTypes,
    isFetching,
    isLoading,
  };
};
