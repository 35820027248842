/*
should be able to display a message like "We did not find any results that match your filter criteria, please update your filters."
should be able to perform an action when action text is clicked



*/

import { twMerge } from 'tailwind-merge';

export interface ItemsNotFoundCTAProps extends React.HTMLAttributes<HTMLDivElement> {}

export const ItemsNotFoundCTA = ({ className, children, ...rest }: ItemsNotFoundCTAProps) => {
  return (
    <div className={twMerge('pt-2', className)} {...rest}>
      {children}
    </div>
  );
};
