import { Tag } from '../../../components/common/tag';
import { PeerCardExperience } from './experiences-section';
import { useAudioPlayer } from '../../../components/experienceComponents/useAudioPlayer';
import { AudioControls } from '../../../components/experienceComponents/audio-controls';

export interface ExperienceCardProps {
  experience: PeerCardExperience;
}

export const ExperienceCard = ({ experience }: ExperienceCardProps) => {
  const {
    audioRef,
    paused,
    percent,
    experienceRating,
    audioStopAll,
    onPlay,
    onPause,
    onTimeUpdate,
    handleRatingClick,
  } = useAudioPlayer(experience);

  return (
    <div id="experience-card" className="w-[336px] font-['Manrope'] bg-white rounded-[10px] p-4">
      <audio ref={audioRef} src={experience.audio_url} onTimeUpdate={onTimeUpdate} onPlay={onPlay} />
      <div id="tag-container" className="pb-4 flex gap-x-2 gap-y-2 flex-wrap">
        {experience.tag_names?.map((tag) => <Tag key={tag} tag={tag} />)}
      </div>
      <div className="mb-4 w-[301px] text-[#222833] text-base  leading-[21px] line-clamp-5 overflow-hidden text-wrap truncate">
        {experience.admin_summary || experience.excerpt}
      </div>
      <AudioControls
        paused={paused}
        onPlay={onPlay}
        onPause={onPause}
        percent={percent}
        experienceRating={experienceRating}
        handleRatingClick={handleRatingClick}
        duration={experience.duration}
        audioStopAll={audioStopAll}
      />
    </div>
  );
};
