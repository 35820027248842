import { twMerge } from 'tailwind-merge';

import { Button, Dialog, DialogProps } from '@kindlyhuman/component-library';

import moment from 'moment';

export const CancelUserPlanModal = ({ className, onClose, ...props }: DialogProps) => {
  return (
    <Dialog
      onClose={onClose}
      closeOnOutsideClick={true}
      className={twMerge('rounded-2xl backdrop:bg-modalBackdropColor p-0', className)}
      {...props}
    >
      <div className="w-90 p-8 space-y-2 text-center">
        <div className="self-stretch text-center text-gray-800 text-xl font-bold font-manrope">
          Sure you want to cancel your Kindly Human plan?
        </div>
        <div className="text-center text-stone-500 font-manrope">
          Your account is paid up thorough {moment().set('date', 1).add(1, 'M').format('MMMM D, YYYY')} and will remain
          active until that date
        </div>
      </div>
      <div className="flex flex-col gap-2 p-4 border-t border-neutral-200">
        {/* TODO: add plan cancellation logic when this functionality will work */}
        <Button onClick={onClose} variant="secondary">
          Cancel plan
        </Button>
        <Button variant="primary" onClick={onClose}>
          Keep subscription
        </Button>
      </div>
    </Dialog>
  );
};
