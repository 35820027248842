import { useState } from 'react';
import { MobileHeader, DesktopHeader, analytics, EventTypes } from '@kindlyhuman/component-library';
import { ProfileStep } from '..';
import { User, useUser } from '../../hooks/useUser';
import { SetPassword } from './set-password';
import { twMerge } from 'tailwind-merge';

import { isDesktop } from 'react-device-detect';
import { useHistory, Redirect } from 'react-router';
import { ROUTE_PATH } from '../../routes/route-paths';
import moment from 'moment';
import { CacheAxiosResponse } from 'axios-cache-interceptor';
import { useHubspotEventMutation } from '../../hooks/useEvent';
import useAuth from '../../hooks/useAuth';

const OnboardingStepper = () => {
  const { user } = useAuth();
  const { updateUser } = useUser();
  const hubspotMutation = useHubspotEventMutation();
  const [step, setStep] = useState(0);
  const history = useHistory();

  const handleNext = () => {
    setStep(step + 1);
  };

  const handleDone = () => {
    updateUser.mutate(
      { is_partial: false },
      {
        onSuccess: (data: CacheAxiosResponse<User, any>) => {
          hubspotMutation.mutate({
            event: 'onboarding_completed',
            properties: {
              package_code: user?.caller_role.active_subscription?.package.code!,
            },
          });
          analytics.trackEvent(
            EventTypes.COMPLETED_ONBOARDING,
            user?.client_name,
            data?.data?.gender,
            // getting the nearest decade
            Math.floor(moment().diff(data?.data?.date_of_birth, 'years') / 10) * 10,
          );
          if (isDesktop) {
            history.push(ROUTE_PATH.WELCOME_VIDEO);
          } else {
            history.push(ROUTE_PATH.ADD_TO_HOME);
          }
        },
      },
    );
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return <SetPassword onComplete={handleNext} />;
      case 1:
        return <ProfileStep onComplete={handleDone} />;
    }
  };

  return (
    <>
      <div className="min-h-screen flex flex-col">
        <DesktopHeader className="hidden md:block relative z-10" />
        <MobileHeader className="md:hidden relative z-10" />
        <div
          className="
            pb-20 bg-white h-full grow overflow-x-hidden
            md:bg-whiteSmoke
          "
        >
          <div
            className={twMerge(
              'px-5 mx-auto mt-5 md:mt-20',
              step <= 2 ? 'md:max-w-160' : 'md:max-w-7xl md:mx-auto md:px-6',
            )}
          >
            {renderStep()}
          </div>
        </div>
      </div>
    </>
  );
};

export default OnboardingStepper;
