import { Dispatch, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MobileMenuHeader, DesktopMenuHeader } from '../../components/MenuHeader';
import { useAvailableListeners, useUnavailableListeners } from '../../hooks/usePeers';
import { SchedulingModal } from '../../components/scheduling-modal/scheduling-modal';
import {
  ConnectNowPeerCard,
  ConnectNowPeerCardSkeleton,
} from '../../components/mwa-3.5-redesign/connect-now-peer-card';
import { ROUTE_PATH } from '../../routes/route-paths';
import { BackgroundStripe } from '../../components/mwa-3.5-redesign/bg-stripe';

const AvailableListenersPage = () => {
  const navigate = useHistory();
  const [scheduleModal, setScheduleModal] = useState<number>();
  const [scheduleAvailableNow, setScheduleAvailableNow] = useState(false);

  return (
    <>
      <div className="bg-neutral-700 bg-opacity-5 min-h-screen flex flex-col">
        <MobileMenuHeader onBack={() => navigate.goBack()} />
        <DesktopMenuHeader />
        <Body setScheduleModal={setScheduleModal} setScheduleAvailableNow={setScheduleAvailableNow} />
        <BackgroundStripe />
      </div>
      {scheduleModal && (
        <SchedulingModal
          open={!!scheduleModal}
          listenerAvailableNow={scheduleAvailableNow}
          onExit={(context) => {
            if (context === 'scheduled') {
              navigate.push(ROUTE_PATH.HOME);
            }
            setScheduleModal(undefined);
          }}
          listenerId={scheduleModal}
        />
      )}
    </>
  );
};

const Body: React.FC<{
  setScheduleModal: Dispatch<React.SetStateAction<number | undefined>>;
  setScheduleAvailableNow: Dispatch<React.SetStateAction<boolean>>;
}> = ({ setScheduleModal, setScheduleAvailableNow }) => {
  const availableListeners = useAvailableListeners();
  const unavailableListeners = useUnavailableListeners();

  return (
    <div className="w-full space-y-3 md:space-y-6 md:max-w-7xl md:mx-auto md:px-6 md:pb-10 ">
      <div>
        <div className="flex flex-col px-3 pt-2 gap-4">
          <div>
            <div className="text-2xl font-textaBlack leading-normal">Connect Now</div>
            <div className="text-sm font-thin opacity-70 font-texta leading-normal">
              Listeners available to connect right now
            </div>
          </div>
          <div className="flex flex-col md:flex-row flex-wrap gap-3 mx-auto md:mx-0">
            {availableListeners.isLoading && (
              <>
                <ConnectNowPeerCardSkeleton /> <ConnectNowPeerCardSkeleton />
              </>
            )}
            {availableListeners?.data?.data?.map((peer) => (
              <ConnectNowPeerCard
                key={peer.listener_role_id}
                peer={peer}
                setScheduleModal={setScheduleModal}
                setScheduleAvailableNow={setScheduleAvailableNow}
              />
            ))}
          </div>
        </div>
      </div>
      <div>
        <div className="flex flex-col px-3 pt-2 gap-4">
          <div>
            <div className="text-2xl font-textaBlack leading-normal">Connect Later</div>
            <div className="text-sm font-thin opacity-70 font-texta leading-normal">
              Schedule a time to connect with a listener
            </div>
          </div>
          <div className="flex flex-col md:flex-row flex-wrap gap-3 mx-auto md:mx-0">
            {unavailableListeners.isLoading && (
              <>
                <ConnectNowPeerCardSkeleton /> <ConnectNowPeerCardSkeleton />
              </>
            )}
            {unavailableListeners?.data?.data?.map((peer) => (
              <ConnectNowPeerCard
                key={peer.listener_role_id}
                peer={peer}
                setScheduleModal={setScheduleModal}
                setScheduleAvailableNow={setScheduleAvailableNow}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AvailableListenersPage;
