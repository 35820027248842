import { HeartIcon } from '@kindlyhuman/component-library';

interface FollowPillProps {
  isFollowing: boolean;
  handleFollowing: () => void;
}

export const FollowPill: React.FC<FollowPillProps> = ({ isFollowing, handleFollowing }) => {
  return (
    <div
      className="w-24 border border-[#081d40] rounded-full flex px-2 items-center gap-2 text-sm font-texta antialiased leading-normal hover:cursor-pointer"
      onClick={handleFollowing}
    >
      <HeartIcon width={18} color="#081d40" fill={isFollowing ? 'true' : 'false'} />{' '}
      {isFollowing ? 'Following' : 'Follow'}
    </div>
  );
};
