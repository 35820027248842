import { Avatar, Button, Dialog, ExitIcon, PhoneIcon } from '@kindlyhuman/component-library';
import { Experience } from '../../../hooks/useExperiences';
import { ROUTE_PATH } from '../../../routes/route-paths';
import { Link } from 'react-router-dom';
import { useAudioPlayer } from '../../../components/experienceComponents/useAudioPlayer';
import { AudioControls } from '../../../components/experienceComponents/audio-controls';

export interface ExperienceModalProps {
  experience: Experience;
  open: boolean;
  onClose: () => void;
  handleScheduleModalOpen: (experience: Experience) => void;
}

export const ExperienceModal = ({ experience, open, onClose, handleScheduleModalOpen }: ExperienceModalProps) => {
  const { audioRef, paused, percent, experienceRating, onPlay, onPause, onTimeUpdate, handleRatingClick } =
    useAudioPlayer(experience);

  const close = () => {
    if (audioRef.current) {
      audioRef.current?.pause();
      audioRef.current.currentTime = 0;
    }
    onClose();
  };

  return (
    <Dialog
      className="max-w-98 p-6 w-11/12 bg-white rounded-[10px] backdrop:bg-modalBackdropColor"
      open={open}
      onClose={close}
    >
      <audio ref={audioRef} src={experience.audio_url} onTimeUpdate={onTimeUpdate} onPlay={onPlay} />
      <header className="flex mb-4">
        <Link to={`${ROUTE_PATH.PEER_DETAILS}?listenerId=${experience.listener_role_id}`}>
          <Avatar
            variant="rounderSmall"
            image={experience.profile_photo_square_file_url}
            available={experience.is_available}
          />
        </Link>
        <div className="flex grow justify-between">
          <Link
            to={`${ROUTE_PATH.PEER_DETAILS}?listenerId=${experience.listener_role_id}`}
            className="flex flex-col gap-y-2 ml-6"
          >
            <div className="text-[#222833] text-xl font-extrabold font-['Texta'] leading-snug">{experience.name}</div>
            {experience.is_listener && (
              <div className="flex gap-2">
                <div className="rounded-full w-5 h-5 bg-[#2ee5da] flex justify-center items-center">
                  <PhoneIcon color="black" width={11} />
                </div>
                <div className="text-sm font-texta antialiased leading-normal">Listener</div>
              </div>
            )}
          </Link>
          <button className="h-6" onClick={close}>
            <ExitIcon width={26} height={26} color="black" />
          </button>
        </div>
      </header>
      <div className="text-[#222833] text-base font-semibold leading-[21px] mb-4">
        {experience.admin_summary || experience.excerpt}
      </div>
      <AudioControls
        paused={paused}
        onPlay={onPlay}
        onPause={onPause}
        percent={percent}
        experienceRating={experienceRating}
        handleRatingClick={handleRatingClick}
      />
      <Link to={`${ROUTE_PATH.PEER_DETAILS}?listenerId=${experience.listener_role_id}`}>
        <Button className="w-full mb-2" variant="primary">
          Meet {experience.name}
        </Button>
      </Link>
      {experience.is_listener && (
        <Button onClick={() => handleScheduleModalOpen(experience)} className="w-full mb-2" variant="secondary">
          Schedule a call
        </Button>
      )}
    </Dialog>
  );
};
