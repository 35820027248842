import { PauseIcon, PlayIcon } from '@kindlyhuman/component-library';
import { ThumbsDownIcon, ThumbsUpIcon } from '../../assets';

interface AudioControlsProps {
  paused: boolean;
  onPlay: () => void;
  onPause: () => void;
  percent: number;
  experienceRating: number;
  handleRatingClick: (rating: number) => void;
  duration?: number;
  audioStopAll?: () => void;
}

export const AudioControls: React.FC<AudioControlsProps> = ({
  paused,
  onPlay,
  onPause,
  percent,
  experienceRating,
  handleRatingClick,
  duration,
  audioStopAll,
}) => {
  const formatTimestamp = (duration: number) => {
    const minutes = Math.floor(duration / 60);
    const seconds = duration % 60;
    return `${minutes.toString().padStart(2, '0')}:${Math.round(seconds).toString().padStart(2, '0')}`;
  };

  const handlePlayClick = () => {
    if (paused) {
      if (audioStopAll) {
        audioStopAll();
        onPlay();
      } else {
        onPlay();
      }
    } else {
      onPause();
    }
  };

  return (
    <div className={`flex flex-nowrap gap-x-4 items-center justify-between ${duration ? 'my-2' : 'my-8'}`}>
      <div className="w-10 h-10 rounded-full bg-gray-50 border-gray-100 left-5 inline-flex">
        <button data-testid="play-experience" className="cursor-pointer m-auto" onClick={handlePlayClick}>
          {paused ? <PlayIcon /> : <PauseIcon />}
        </button>
      </div>
      {duration && (
        <span className="text-center text-[#666666] text-base font-normal leading-normal">
          {formatTimestamp(duration)}
        </span>
      )}
      <div
        style={{
          width: '100%',
          background: `linear-gradient(to right, #AFFF54 ${percent}%, #F6F6F6 0%)`,
          height: '4px',
        }}
      />
      <div className="flex gap-x-2 cursor-pointer">
        <ThumbsUpIcon onClick={() => handleRatingClick(1)} isFill={experienceRating > 0} />
        <ThumbsDownIcon onClick={() => handleRatingClick(-1)} isFill={experienceRating < 0} />
      </div>
    </div>
  );
};
