import { Controller, useFormContext } from 'react-hook-form';
import { Subheader } from '..';
import { OptionRadio } from '@kindlyhuman/component-library';
import { PostCall } from '../../../../hooks/usePostCall';
import { twMerge } from 'tailwind-merge';

const connectAgainOptions = [
  { name: 'Yes', value: true },
  { name: 'No', value: false },
];

export interface ConnectAgainProps {
  postCall?: PostCall;
  className?: string;
}

export const ConnectAgain: React.FC<ConnectAgainProps> = ({ postCall, className }) => {
  const { control } = useFormContext();

  return (
    <div className={twMerge('space-y-5', className)}>
      <Subheader
        className="px-4 md:px-0"
        question={
          <>
            Would you be open to connecting with{' '}
            <span className="font-bold">{postCall?.call.listener_role.user.display_name}</span> again?
          </>
        }
        postCall={postCall}
      />
      <div className="bg-white">
        {connectAgainOptions?.map(({ name, value: selectionValue }) => (
          <Controller
            key={name}
            control={control}
            name="connectAgain"
            render={({ field: { value, onChange } }) => (
              <label htmlFor={name} className="cursor-pointer">
                <OptionRadio
                  id={name}
                  label={name}
                  // @ts-ignore
                  value={selectionValue}
                  checked={selectionValue === value}
                  onChange={() => onChange(selectionValue)}
                  className="md:border-0 md:!bg-transparent md:px-0"
                />
              </label>
            )}
          />
        ))}
      </div>
    </div>
  );
};
