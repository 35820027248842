import { FunctionComponent } from 'react';
import { twMerge } from 'tailwind-merge';

import { DropdownMenu, DropdownMenuItem } from '@kindlyhuman/component-library';

export type DependentCardProps = {
  displayName: string;
  emailAddress: string;
  phoneNumber: string;
  pending: boolean;
  menuItems: DropdownMenuItem[];

  className?: string;
};

export const DependentCard: FunctionComponent<DependentCardProps> = ({
  displayName,
  emailAddress,
  phoneNumber,
  pending,
  menuItems,

  className,
}) => {
  return (
    <div
      className={twMerge(
        'relative pb-4 bg-white rounded-lg shadow border border-neutral-200 justify-start items-start inline-flex',
        className,
      )}
    >
      <div className="grow shrink basis-0 pt-4 flex-col justify-start items-start gap-3 inline-flex">
        {pending && (
          <div className="pl-4 pr-3 py-1 bg-lime-300 bg-[#AFFF54] bg-opacity-30 rounded-tr-lg rounded-br-lg justify-start items-start gap-2 inline-flex">
            <div className="text-gray-800 text-sm font-medium leading-tight">Pending</div>
          </div>
        )}
        <div className="self-stretch px-4 flex-col justify-start items-start gap-2 flex">
          <div data-testid="dependent-displayName" className="self-stretch text-gray-800 text-lg font-bold leading-7">
            {displayName}
          </div>
          <div
            data-testid="dependent-emailAddress"
            className="self-stretch text-stone-500 text-sm font-medium leading-tight"
          >
            {emailAddress}
          </div>
          <div
            data-testid="dependent-phoneNumber"
            className="self-stretch text-stone-500 text-sm font-medium leading-tight"
          >
            {phoneNumber}
          </div>
        </div>
      </div>
      {menuItems && (
        <div className="absolute top-4 right-3">
          <DropdownMenu items={menuItems} />
        </div>
      )}
    </div>
  );
};
