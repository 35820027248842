import React from 'react';
import { NotFoundJpeg } from '../../assets';
import { useHistory } from 'react-router-dom';
import { ROUTE_PATH } from '../../routes/route-paths';
import { StickyStateKeys } from '../../hooks/useStickyState';

const NotFound404: React.FunctionComponent = (): JSX.Element => {
  const history = useHistory();
  const authToken = localStorage.getItem(StickyStateKeys.AuthorizationToken);
  const goBack = () => {
    history.push(authToken ? ROUTE_PATH.HOME : ROUTE_PATH.WELCOME_PAGE);
  };
  return (
    <div className="h-screen bg-white">
      <div className="w-full h-full flex justify-center">
        <div className="w-screen xsm:w-100 px-7.5 flex items-center">
          <div>
            <img src={NotFoundJpeg} alt="not-found" />
            <h3 className="text-primary font-bold text-center text-xl">Oops!</h3>
            <h3 className="text-dark-blue font-medium text-center text-xl mt-4">
              It looks like we’ve reached a wrong number.
            </h3>
            <h3 className="text-center text-primary font-medium text-xl mt-4">Error 404</h3>
            <button className="btn btn-blue h-11 w-full font-bold mt-4" onClick={goBack}>
              GO BACK
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default NotFound404;
