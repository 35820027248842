import { FunctionComponent, HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

import { NoCallsImage } from '../../../assets';

export const NoCallsCard: FunctionComponent<HTMLAttributes<HTMLDivElement>> = ({ className, ...props }) => {
  return (
    <div className={twMerge('', className)} {...props}>
      <img src={NoCallsImage} alt="No calls here yet" />
      <p className="mt-6 text-center text-lg text-dark font-bold font-manrope">No calls here yet</p>
    </div>
  );
};
