import { Button, Step } from '@kindlyhuman/component-library';
import { Link } from 'react-router-dom';
import { ROUTE_PATH } from '../../../routes/route-paths';
import { AndroidTooltip, IosTooltip } from '../../../assets';

export const AddToHome = () => {
  // Assuming the default is non-Android as that matches mocks, stakeholders, and most development environments
  const isAndroid = navigator.userAgent.toLowerCase().includes('android');

  return (
    <div className="py-10 px-5 md:max-w-screen-xsm md:mx-auto">
      <div className="flex justify-center">
        <img
          className="max-w-[335px] w-full"
          src={isAndroid ? AndroidTooltip : IosTooltip}
          alt={`How to Add to Home on ${isAndroid ? 'Android' : 'iOS'}`}
        />
      </div>

      <p className="text-center font-manrope text-[#240089] font-semibold text-[1.5rem] my-5">
        Save Kindly Human to your Home Screen
      </p>

      <ol className="text-[#222833] font-manrope font-light">
        <li>
          <Step title={''} selected={false} completed={false} step={1} className="mr-2" />
          Tap {isAndroid ? 'the' : 'on'} {isAndroid && <TripleDots className="inline-block mx-1" />}{' '}
          {isAndroid ? 'menu' : 'share'} button
        </li>
        <li className="mt-4">
          <Step title={''} selected={false} completed={false} step={2} className="mr-2" />
          Tap on &ldquo;{isAndroid ? 'Install App' : 'Add to Home Screen'}&rdquo;
        </li>
        <li className="mt-4">
          <Step title={''} selected={false} completed={false} step={3} className="mr-2" />
          Access Kindly Human any time!
        </li>
      </ol>

      <div className="py-6 px-4 fixed bottom-0 left-0 w-full bg-white">
        <Link to={ROUTE_PATH.WELCOME_VIDEO}>
          <Button variant="primary" className="w-full block mx-auto md:w-40">
            Continue
          </Button>
        </Link>
      </div>
    </div>
  );
};

const TripleDots = ({ className }: { className?: string }) => {
  return (
    <svg width="4" height="18" viewBox="0 0 4 18" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
      <circle cx="1.91304" cy="8.80001" r="1.91304" fill="black" />
      <circle cx="1.91304" cy="15.687" r="1.91304" fill="black" />
      <circle cx="1.91304" cy="1.91304" r="1.91304" fill="black" />
    </svg>
  );
};
