import React from 'react';
import { useHistory } from 'react-router-dom';

interface AffiliationTileProps {
  path?: string;
  image: string;
  name: string;
  caption: string;
}

export const AffiliationTile: React.FC<AffiliationTileProps> = ({ path, image, name, caption }) => {
  const history = useHistory();

  return (
    <div
      className={`bg-white rounded-[10px] flex items-center gap-2 p-2 min-w-[336px] select-none ${path && 'cursor-pointer'}`}
      onClick={path ? () => history.push(path) : undefined}
    >
      <div className="w-1/2 overflow-hidden">
        <img src={image} alt="Resource logo" className="rounded-md" />
      </div>
      <div className="flex flex-col py-2 w-1/2">
        <div data-testid={``} className={`text-xl font-textaBlack leading-tight`}>
          {name}
        </div>
        <div className={`text-[12px] font-manrope leading-tight text-[#666666]`}>{caption}</div>
      </div>
    </div>
  );
};

// Not currently in use, but it's here if needed
export const AffiliationTileSkeleton: React.FC = () => {
  return (
    <div className="bg-gray-300 rounded-[10px] flex gap-2 animate-pulse p-1">
      <div className="w-36 h-16 m-2 bg-gray-400 rounded flex-shrink-0" />
      <div className="flex flex-col py-3 gap-2 w-full">
        <div className="h-6 bg-gray-400 rounded w-3/4" />
        <div className="h-4 bg-gray-400 rounded w-1/2" />
      </div>
    </div>
  );
};
