import { ROUTE_PATH } from './route-paths';
import { Switch } from 'react-router-dom';
// Page Imports
import { OnboardingStepper, SelectPlan } from '../containers';

// These could be added to the containers index
import { Redirect, Route } from 'react-router';
import WelcomeVideo from '../containers/onboarding/components/welcome-video';
import { AddToHome } from '../containers/onboarding/components/add-to-home';

const onboardingRoutes = [
  {
    path: ROUTE_PATH.ONBOARDING,
    component: OnboardingStepper,
  },
  {
    path: ROUTE_PATH.WELCOME_VIDEO,
    component: WelcomeVideo,
  },
  {
    path: ROUTE_PATH.ADD_TO_HOME,
    component: AddToHome,
  },
  //   {
  //     path: ROUTE_PATH.SELECT_PLAN,
  //     component: SelectPlan,
  //   },
  //   {
  //     path: ROUTE_PATH.ONBOARDING_PAYMENT,
  //     component: OnboardingPayment,
  //   },
];

export const OnboardingRouter = () => {
  return (
    <Switch>
      {onboardingRoutes.map(({ path, component }) => (
        <Route exact path={path} component={component} />
      ))}
      <Route exact path="*" render={() => <Redirect to={ROUTE_PATH.ONBOARDING} />} />
    </Switch>
  );
};
