import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { MobileMenuHeader, DesktopMenuHeader } from '../../components/MenuHeader';
import { ToggleWithLabel, Button } from '@kindlyhuman/component-library';
import { User, useUser } from '../../hooks/useUser';
import { useForm, SubmitHandler } from 'react-hook-form';
import Toast from '../../components/common/PopUpMessage';
import { FormBlock } from '../../components/common/form_block';
import { UnsavedChangesModal } from '../../components/UnsavedChangesModal';
import useAuth from '../../hooks/useAuth';

export interface NotificationsProfileData {
  notifications_listener_online: boolean;
  notifications_check_ins: boolean;
}

const MyPreferencesPage = (): React.JSX.Element => {
  const navigate = useHistory();
  const { user } = useAuth();
  const { updateUser } = useUser();
  const [saveChangesModal, setSaveChangesModal] = useState(false);

  const generateDefaultValues = (user: User | null): Partial<NotificationsProfileData> => ({
    notifications_listener_online: user?.caller_role?.notifications_listener_online,
    notifications_check_ins: user?.caller_role?.notifications_check_ins,
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { isDirty, isSubmitting },
  } = useForm({
    defaultValues: React.useMemo(() => {
      return generateDefaultValues(user);
    }, [user]),
    mode: 'onChange',
  });

  useEffect(() => {
    if (user) {
      reset(generateDefaultValues(user));
    }
  }, [user, reset]);

  const submit: SubmitHandler<Partial<NotificationsProfileData>> = async (data) => {
    await updateUser.mutateAsync({
      caller_role: {
        ...user?.caller_role!,
        notifications_listener_online: data.notifications_listener_online!,
        notifications_check_ins: data.notifications_check_ins!,
      },
    });

    Toast.success('Profile updated successfully');

    if (saveChangesModal) {
      setSaveChangesModal(false);
      navigate.goBack();
    }
  };

  return (
    <form onSubmit={handleSubmit(submit)}>
      <DesktopMenuHeader />
      <MobileMenuHeader
        onBack={() => {
          isDirty ? setSaveChangesModal(true) : navigate.goBack();
        }}
      />
      <div className="min-h-screen w-full bg-neutral-700 bg-opacity-5 space-y-4">
        <Header />
        <FormBlock>
          <ToggleWithLabel
            header="My Listeners"
            subHeader="Receive reminders when my Listeners are on-call"
            checked={watch('notifications_listener_online') ?? false}
            {...register('notifications_listener_online')}
            onChange={(checked: boolean) => {
              setValue('notifications_listener_online', checked, { shouldDirty: true });
            }}
          />
        </FormBlock>
        <FormBlock>
          <ToggleWithLabel
            header="Reflection Reminders"
            subHeader="Allow occasional reminders to take a moment and reflect"
            checked={watch('notifications_check_ins') ?? false}
            {...register('notifications_check_ins')}
            onChange={(checked: boolean) => {
              setValue('notifications_check_ins', checked, { shouldDirty: true });
            }}
          />
        </FormBlock>
        {isDirty ? (
          <div className="fixed bottom-0 left-0 right-0 py-4 bg-white border-t border-neutral-200">
            <div className="flex items-center justify-center">
              <Button variant="primary" type="submit" disabled={!isDirty || isSubmitting} loading={isSubmitting}>
                Save Changes
              </Button>
            </div>
          </div>
        ) : (
          ''
        )}
        <UnsavedChangesModal
          open={saveChangesModal}
          onClose={() => {
            setSaveChangesModal(false);
          }}
          onDiscard={() => navigate.goBack()}
          savingInProgress={isSubmitting}
        />
      </div>
    </form>
  );
};

const Header = () => (
  <div className="self-stretch text-gray-800 text-2xl font-bold leading-loose px-4 pt-4 text-center">Notifications</div>
);

export default MyPreferencesPage;
