import { useQuery } from '@tanstack/react-query';
import { axiosGet } from '../api/axios-handler';

export interface PackageDetails {
  archived_at: any;
  benefits_description: string;
  branding_configuration: BrandingConfiguration;
  can_manage_dependents: boolean;
  client: Client;
  code: string;
  collect_user_address: boolean;
  dependents_configuration: DependentsConfiguration;
  external_plan_id: number;
  force_web_app: any;
  id: number;
  name: string;
  package_products: PackageProduct[];
  subscriptions: any[];
  theme_name: any;
}

export interface BrandingConfiguration {
  button_label: string;
  enabled: boolean;
  image_url: string;
  placements: string[];
  type: string;
  url: string;
  video_url: any;
}

// TODO this type is also defined in useUser.tsx and useClientCode.tsx.
// These should be consolidated into a single declaration.
export interface Client {
  id: number;
  landing_page_content: any;
  logo_background_file_url: any;
  logo_file_url: string;
  logo_overlay_file_url: any;
  member_self_enrollment: boolean;
  name: string;
  promo_configuration: PromoConfiguration;
  sso_configuration?: any;
  video_configuration: VideoConfiguration;
}

export interface PromoConfiguration {
  button_label: string;
  image_url: string;
  placements: string[];
  type: string;
  url: string;
  video_url: string;
}

export interface VideoConfiguration {
  image_url: string;
  title: string;
  video_url: string;
}

export interface DependentsConfiguration {
  dependent_count: number;
  enabled: boolean;
  members_can_add_dependents: boolean;
}

export interface PackageProduct {
  configuration: Configuration;
  created_at: string;
  id: number;
  package_id: number;
  product: Product;
  product_id: number;
  status: string;
}

export interface Configuration {
  image_file?: string;
  required_points?: number;
  reward_value?: number;
  engagement_points?: number;
  interim_question?: string;
  primary_question?: string;
  hide_payment_tiles?: boolean;
  maximum_minutes_per_user?: number;
}

export interface Product {
  id: number;
  key: string;
  product_type: string;
}

const getPackageDetails = async (packageCode: string): Promise<PackageDetails> => {
  return await axiosGet(`/clients/package_details/${packageCode}`, {}).then(
    (resourceResponse) => resourceResponse.data,
  );
};

export const usePackageDetails = (packageCode: string) =>
  useQuery<PackageDetails>(['packageDetails'], () => getPackageDetails(packageCode), {
    staleTime: Infinity,
  });

export const isGroupSelfEnroll = async (
  packageCode: string,
  success: (newValue: boolean) => void,
  fail: (reason: any) => void,
) => {
  await getPackageDetails(packageCode)
    .then((pkg: PackageDetails) => {
      success(pkg?.client.member_self_enrollment);
    })
    .catch(fail);
};
