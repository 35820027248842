import React, { ChangeEvent, useEffect, useState } from 'react';
import { Link, Redirect, useHistory, useLocation } from 'react-router-dom';
import {
  PartialMember,
  getPartialMember,
  useSend2FAEmail,
  useResendOnboardingLink,
  useSendKeycloakVerificationLink,
} from '../../hooks/useUser';
import { ROUTE_PATH } from '../../routes/route-paths';
import { Button, CircledArrowRightIcon, LeftArrowIcon, MobileHeader } from '@kindlyhuman/component-library';
import { WelcomeSlogan } from '../../components/common/WelcomeSlogan';
import { Features, useFeatureFlag } from '../../hooks/useFeatureFlag';
import Toast from '../../components/common/PopUpMessage';
import { Spinner } from '../../components/common';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import './verification.css';
import useAuth from '../../hooks/useAuth';
import { LoginButton } from '../../components/common/login-button';
import { KindlyHumanSvg } from '../../assets';

export const VerificationPage: React.FunctionComponent = (): JSX.Element => {
  const navigate = useHistory();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const emailAddress = queryParams.get('email_address');
  const mode = queryParams.get('mode');
  const sendKeycloakVerificationLink = useSendKeycloakVerificationLink();
  const resendOnboardingLink = useResendOnboardingLink();
  const send2FAEmail = useSend2FAEmail();
  const { isKeycloakEnabled } = useAuth();
  const isKeycloak = useFeatureFlag(Features.MWA_KEYCLOAK_LOGIN);
  const isMfa = useFeatureFlag(Features.MULTI_FACTOR_AUTHENTICATION);
  const dfMdMedia = useMediaQuery('md');

  const [partialMember, setPartialMember] = useState<PartialMember | undefined>(undefined);
  const [sentLink, setLinkSent] = useState(false);

  const isWelcome = mode === 'welcome';

  const sendMfaCode = () => {
    send2FAEmail.mutate(
      {
        email_address: emailAddress!,
      },
      {
        onSuccess: () => {
          Toast.success('Verification code sent successfully.');
          setLinkSent(true);
        },
        onError: (err: any) => {
          Toast.error(err.response.data.description);
        },
      },
    );
  };
  const sendVerificationLink = (type: 'text' | 'email', partialMember?: PartialMember, isResend = false) => {
    if (!partialMember) {
      return;
    }
    if (isKeycloakEnabled) {
      sendKeycloakVerificationLink.mutate(
        {
          userId: partialMember.user_id,
        },
        {
          onSuccess: () => {
            if (isResend) {
              Toast.success('Verification link sent successfully.');
              setLinkSent(true);
            }
          },
          onError: (err: any) => {
            Toast.error(err.response.data.description);
          },
        },
      );
    } else {
      resendOnboardingLink.mutate(
        { userId: partialMember.user_id, type: type },
        {
          onSuccess: () => {
            if (isResend) {
              Toast.success('Verification link sent successfully.');
              setLinkSent(true);
            }
          },
          onError: (err: any) => {
            Toast.error(err.response.data.description);
          },
        },
      );
    }
  };

  useEffect(() => {
    if (isKeycloak.isLoading) {
      return;
    }
    getPartialMember(
      { user_id: JSON.parse(sessionStorage.getItem('partial_member_id')!) },
      (partialMember) => {
        setPartialMember(partialMember);
        // for verification links, we send immediately on page load. For MFA code, we only send on button click.
        if (isKeycloakEnabled || !isMfa.enabled) {
          sendVerificationLink('email', partialMember);
        }
      },
      (err) => {
        console.error(err);
        Toast.error("That didn't work. please try again.");
        navigate.push(ROUTE_PATH.SIGNUP);
      },
    );
  }, [navigate, isKeycloak.isLoading]);

  if (isKeycloak.isLoading) {
    return <Spinner />;
  }

  // we don't want a situation where the user can access this page without an email address, like navigating straight to it
  // TODO: CLJ EB MAYBE this should be handled by the router
  if ((!isKeycloak.isLoading && !emailAddress) || !emailAddress) {
    return <Redirect to={ROUTE_PATH.SIGNUP} />;
  }

  // send a verification link if we're in keycloak mode, or if we're not in keycloak mode and MFA turned off
  if (isKeycloakEnabled || !isMfa.enabled) {
    return (
      <EmailLinkVerification
        navigate={navigate}
        sentLink={sentLink}
        dfMdMedia={dfMdMedia}
        sendVerificationLink={sendVerificationLink}
        partialMember={partialMember}
        isWelcome={isWelcome}
        isKeycloak={isKeycloak.enabled}
      />
    );
  }
  return (
    <MFAVerification
      navigate={navigate}
      setLinkSent={(value: boolean) => setLinkSent(value)}
      sentLink={sentLink}
      sendVerificationLink={sendMfaCode}
      resendVerificationLink={sendMfaCode}
      partialMember={partialMember}
    />
  );
};

interface VerificationProps {
  navigate: any;
  sentLink: boolean;
  sendVerificationLink: (type: 'text' | 'email', partialMember?: PartialMember, isResend?: boolean) => void;
  resendVerificationLink?: () => void;
  partialMember: PartialMember | undefined;
}

interface MFAVerificationProps extends VerificationProps {
  setLinkSent: (value: boolean) => void;
}

const MFAVerification = ({
  navigate,
  sentLink,
  sendVerificationLink,
  resendVerificationLink,
  partialMember,
  setLinkSent,
}: MFAVerificationProps) => {
  const { authenticateWithToken } = useAuth();

  const inputRef = React.useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.currentTarget.value;

    if (newValue === '') {
      setInputValue(newValue);
    }

    if (newValue.length <= 6 && /^[0-9]+$/.test(newValue)) {
      setInputValue(newValue);
    }
  };

  const authenticate = (code: string) => {
    if (!partialMember) {
      Toast.error('An error occured. Please try logging in again');
      navigate.replace(ROUTE_PATH.SIGNUP);
    }

    authenticateWithToken.mutate(
      { token: code, userId: partialMember!.user_id },
      {
        onError: () => {
          Toast.error('Invalid code. Please try again');
          setInputValue('');
        },
      },
    );
  };

  useEffect(() => {
    if (!inputRef.current) return;

    inputRef.current.focus();
  });

  return (
    <>
      <MobileHeader className="md:hidden" onBack={() => navigate.goBack()} />
      <div className="grid w-screen grid-cols-1 md:grid-cols-2 md:h-screen">
        <WelcomeSlogan className="hidden md:block" />
        <div className="md:bg-whiteSmoke md:flex md:items-center md:justify-center">
          <div className="px-7.5 mt-8 pb-20 max-w-[480px] mx-auto">
            {sentLink ? (
              <>
                <button
                  onClick={() => setLinkSent(false)}
                  className="flex no-wrap gap-x-2 font-medium text-dark txt-lg pb-8"
                >
                  <LeftArrowIcon color="black" /> Back
                </button>
                <h2 className="text-very-dark-gray font-bold text-3xl pb-6">Account Verification</h2>
                <div className="text-dark leading-loose text-lg pb-4">
                  We just sent you a 6-digit verification code to{' '}
                  <span className="font-bold">{partialMember?.user_email_obfuscated}</span>
                </div>
                <label>
                  <input
                    ref={inputRef}
                    data-testid="one-time-code-input"
                    className="one-time-input"
                    id="one-time-code-input"
                    type="text"
                    onChange={handleInputChange}
                    autoComplete="one-time-code"
                    inputMode="numeric"
                    maxLength={6}
                    pattern="\d{6}"
                    value={inputValue}
                  />
                </label>
                <div className="text-dark py-8 flex no-wrap items-center leading-4">
                  <div className="pr-4">Didn't get a code? </div>
                  <div className="flex no-wrap items-center gap-1 text-primary text-sm font-bold leading-tight">
                    <button
                      data-testid="resend-2fa-button"
                      onClick={() => {
                        sendVerificationLink('email', partialMember, true);
                      }}
                      className="pt-1 self-center mx-auto flex no-wrap gap-1 text-primary text-sm font-bold leading-tight"
                    >
                      Resend
                      <CircledArrowRightIcon />
                    </button>
                  </div>
                </div>
                <Button
                  className="w-full"
                  data-testid="submit-2fa-button"
                  variant="primary"
                  onClick={() => {
                    authenticate(inputValue);
                  }}
                >
                  Verify and Create Account
                </Button>
                <button
                  onClick={() => navigate.replace(ROUTE_PATH.SIGNUP)}
                  className="pt-8 self-center mx-auto flex no-wrap items-center gap-1 text-primary text-sm font-bold leading-tight"
                >
                  Change Email Address
                  <CircledArrowRightIcon />
                </button>
              </>
            ) : (
              <div className="flex flex-col justify-center">
                <button
                  onClick={() => navigate.replace(ROUTE_PATH.SIGNUP)}
                  className="flex no-wrap gap-x-2 font-medium text-dark txt-lg pb-8"
                >
                  <LeftArrowIcon color="black" /> Back
                </button>
                <img src={KindlyHumanSvg} alt="kindly human text logo" />
                <h6 className="text-dark text-lg pt-8 leading-relaxed pb-10">
                  We need to verify your email at{' '}
                  <span className="font-bold">{partialMember?.user_email_obfuscated}</span>. Press the button below to
                  send a new code.
                </h6>
                <Button
                  className="max-w-[200px] mx-auto"
                  data-testid="resend-email-button"
                  variant="primary"
                  onClick={() => {
                    sendVerificationLink('email', partialMember, true);
                  }}
                >
                  Send Code
                </Button>
                <div className="flex justify-center pt-12">
                  <button className="text-primary font-bold leading-tight" onClick={() => setLinkSent(true)}>
                    I already have a code
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

interface EmailVerificationProps extends VerificationProps {
  dfMdMedia: boolean;
  isWelcome: boolean;
  isKeycloak: boolean;
}

const EmailLinkVerification = ({
  navigate,
  sentLink,
  dfMdMedia,
  sendVerificationLink,
  partialMember,
  isWelcome,
  isKeycloak,
}: EmailVerificationProps) => {
  return (
    <>
      <MobileHeader className="md:hidden" onBack={() => navigate.goBack()} />
      <div className="grid w-screen grid-cols-1 md:grid-cols-2 md:h-screen">
        <WelcomeSlogan className="hidden md:block" />
        <div className="md:bg-whiteSmoke md:flex md:items-center md:justify-center">
          <div className="px-7.5 mt-8 pb-20 max-w-[480px] mx-auto">
            {sentLink ? (
              <>
                <p className="text-gray-900 text-2xl not-italic font-bold leading-8 font-manrope">
                  Please check your inbox
                </p>
                <h6 className="text-dark text-lg pt-8 leading-6">A new invite link has been sent.</h6>
                <div className="w-full flex justify-center pt-8 pb-4">
                  <Button
                    data-testid="close-button"
                    variant="primary"
                    onClick={() => {
                      navigate.push(ROUTE_PATH.LOGIN);
                    }}
                  >
                    {dfMdMedia ? 'Login' : 'Close'}
                  </Button>
                </div>
                <div className="w-full flex flex-col items-center gap-4 py-4">
                  <p className="text-sm text-center">
                    Didn't get an email? <br className="md:hidden" />
                    Click the button below to resend
                  </p>
                  <Button
                    data-testid="resend-email-button"
                    variant="primary"
                    onClick={() => {
                      sendVerificationLink('email', partialMember, true);
                    }}
                  >
                    Resend Link to my Email
                  </Button>
                </div>
              </>
            ) : (
              <>
                <p className="text-gray-900 text-2xl not-italic font-bold leading-8 font-manrope">
                  {isWelcome ? 'Welcome!' : "Hmm... that didn't work."}
                </p>
                <h6 className="text-dark text-lg pt-4 leading-6 pb-8">
                  {isWelcome
                    ? 'We need to verify your email. We just sent you an email - please open it and click the link to verify your account.'
                    : 'It looks like the verification code is incorrect or may be expired. We can resend you a new link by email or text'}
                </h6>
                <div className="w-full flex flex-col items-center gap-4">
                  {isWelcome && (
                    <p className="text-sm text-center">
                      Didn’t get an email? <br className="md:hidden" />
                      Click the button below to resend
                    </p>
                  )}
                  <Button
                    data-testid="resend-email-button"
                    variant="primary"
                    onClick={() => {
                      sendVerificationLink('email', partialMember, true);
                    }}
                  >
                    Resend Link to my Email
                  </Button>
                </div>
                <h6 className="text-dark text-lg py-4 w-full flex justify-center">
                  {partialMember?.user_email_obfuscated}{' '}
                </h6>
                {!isKeycloak &&
                  partialMember?.user_phone_obfuscated &&
                  partialMember.user_phone_obfuscated.length > 1 && (
                    <>
                      <br />
                      <div className="pt-4 w-full flex justify-center">
                        <Button
                          data-testid="resend-email-phone"
                          variant="primary"
                          onClick={() => {
                            sendVerificationLink('text', partialMember);
                          }}
                        >
                          Resend Link to my Phone
                        </Button>
                      </div>
                      <h6 className="text-dark text-lg py-4 w-full flex justify-center">
                        {partialMember?.user_phone_obfuscated}{' '}
                      </h6>
                    </>
                  )}
              </>
            )}
            <div className="hidden md:flex justify-between items-center gap-2 mt-4">
              <Link
                className="flex items-center gap-1 text-primary text-sm font-bold leading-tight"
                to={ROUTE_PATH.SIGNUP}
              >
                <CircledArrowRightIcon className="rotate-180" />
                Sign up
              </Link>
              <LoginButton />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
