import { HTMLAttributes, ReactNode } from 'react';
import { twMerge } from 'tailwind-merge';

export interface FormBlockProps extends HTMLAttributes<HTMLDivElement> {
  header?: ReactNode;
  subHeader?: ReactNode;
}

export const formBlockClassName = 'space-y-3 bg-white px-4 md:px-5 py-5 md:rounded-lg md:border border-[#E6E6E6]';

export const FormBlock = ({ header, subHeader, className, children, ...props }: FormBlockProps) => (
  <div className={twMerge(formBlockClassName, className)} {...props}>
    {header && (
      <p className="text-dark text-2xl md:text-xl font-bold leading-loose md:leading-none md:mb-5">{header}</p>
    )}
    {subHeader && <p className="text-neutral-700 text-sm font-medium !-mt-4 !mb-6">{subHeader}</p>}
    {children}
  </div>
);
