import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import { AvatarSkeleton, ChevronDownIcon, PeerCard } from '@kindlyhuman/component-library';
import {
  InfinitePeersQueryResults,
  peersQueryKeys,
  useAddFavoritePeer,
  useInfinitePeers,
  useRemoveFavoritePeer,
} from '../../hooks/usePeers';
import { useTagGroups } from '../../hooks/useTagGroups';
import { useUser } from '../../hooks/useUser';
import { MobileMenuHeader, DesktopMenuHeader, SubHeader } from '../../components/MenuHeader';
import { FilterMenuModal, TagFilters } from '../../components/filter/FomSelectionFilter';
import { SchedulingModal } from '../../components/scheduling-modal/scheduling-modal';
import { ROUTE_PATH } from '../../routes/route-paths';
import { UseInfiniteQueryResult } from '@tanstack/react-query';
import { useMediaQuery } from '../../hooks/useMediaQuery';
import useAuth from '../../hooks/useAuth';

export const RecommendedPeersPage = () => {
  const navigate = useHistory();
  const { user } = useAuth();
  const { updateTagPreferences } = useUser();
  const { data: fomHierarchy } = useTagGroups();
  const peerQuery = useInfinitePeers();
  const dfMdMedia = useMediaQuery('md');

  const [showFilterMenu, setShowFilterMenu] = useState(false);
  const [scheduleModal, setScheduleModal] = useState<number>();

  const handleFilterChange = ({ tag_group_ids, tag_ids }: TagFilters) => {
    updateTagPreferences.mutate({
      tag_group_ids,
      tag_ids,
    });
    setShowFilterMenu(false);
  };

  return (
    <div className="bg-neutral-700 bg-opacity-5">
      <DesktopMenuHeader />
      <MobileMenuHeader onBack={() => navigate.goBack()} />
      <div className="w-full md:max-w-7xl md:mx-auto md:px-6">
        <SubHeader
          title="Say hello to a new Peer"
          subtitle="Peers we think you'll connect with"
          className="px-4 pt-5 pb-7 md:px-0 md:pt-6 md:pb-8"
          onBackButtonClick={dfMdMedia ? () => navigate.goBack() : undefined}
        />
        <Body peerQuery={peerQuery} setScheduleModal={setScheduleModal} />
      </div>
      {showFilterMenu && (
        <FilterMenuModal
          open={showFilterMenu}
          selected={{
            tag_ids: user?.caller_role.tag_ids ?? [],
            tag_group_ids: user?.caller_role.tag_group_ids ?? [],
          }}
          onExit={() => setShowFilterMenu(false)}
          onChange={handleFilterChange}
          fomOptions={fomHierarchy}
        />
      )}
      {scheduleModal && (
        <SchedulingModal
          open={!!scheduleModal}
          listenerAvailableNow={true}
          onExit={() => {
            setScheduleModal(undefined);
          }}
          listenerId={scheduleModal}
        />
      )}
    </div>
  );
};

const Body: React.FC<{
  peerQuery: UseInfiniteQueryResult<InfinitePeersQueryResults, unknown>;
  setScheduleModal: (listenerId: number) => void;
}> = ({ peerQuery, setScheduleModal }) => {
  const navigate = useHistory();
  const addFavPeerMutation = useAddFavoritePeer(peersQueryKeys.infiniteRecommended);
  const removeFavPeerMutation = useRemoveFavoritePeer(peersQueryKeys.infiniteRecommended);
  const { getParentByParentId } = useTagGroups();

  const peers = peerQuery?.data?.pages.map((page) => page.data).flat();

  return (
    <>
      <div className="grid gap-3 md:grid-cols-2 md:gap-6">
        {peers?.map((peer) => (
          <PeerCard
            key={peer.listener_role_id}
            className="overflow-hidden rounded-lg md:border md:border-neutral-200 md:p-5"
            // @ts-ignore
            areasLabels={peer?.challenge_areas?.map((challengeArea) => challengeArea.name)}
            // @ts-ignore
            subjectChips={peer?.subject_areas?.map((subject) => {
              return {
                name: subject.name,
                variant: getParentByParentId(subject.parent_id)?.key,
              };
            })}
            available={peer.is_available}
            isPeerListener={peer.is_listener}
            description={peer.about_me}
            img={peer.profile_photo_url_square}
            name={peer.display_name}
            traits={peer.tags.map((tag) => tag.name)}
            onClick={() =>
              navigate.push({
                pathname: ROUTE_PATH.PEER_DETAILS,
                search: `?listenerId=${peer.listener_role_id}`,
              })
            }
            followed={peer.is_member_following}
            onFollow={
              peer.is_member_following
                ? () => removeFavPeerMutation.mutate(peer.listener_role_id)
                : () => addFavPeerMutation.mutate(peer.listener_role_id)
            }
            onConnect={
              peer.is_available
                ? () => {
                    setScheduleModal(peer.listener_role_id);
                  }
                : undefined
            }
            variant="wide"
          />
        ))}
        {peerQuery.isLoading && [...Array(10).keys()].map((index) => <ExperienceSkeletonWide key={index} />)}
      </div>
      {peerQuery.hasNextPage ? (
        <button
          disabled={peerQuery.isFetchingNextPage}
          onClick={() => peerQuery.fetchNextPage()}
          className="pb-10 pt-6 flex justify-center gap-1 cursor-pointer mx-auto"
        >
          <div className="text-center text-violet-950 text-base font-bold leading-normal">See More</div>
          <ChevronDownIcon width={20} color="#24006B" />
        </button>
      ) : (
        <div className="pt-8" />
      )}
    </>
  );
};

export const ExperienceSkeletonWide: React.FC = () => (
  <div className="flex flex-col px-4 py-2 bg-white">
    <div className="flex items-center justify-between">
      <div className="flex flex-row items-center gap-2">
        <AvatarSkeleton variant="normal" isPeerListener />
        <Skeleton className="h-8 w-16" />
        <Skeleton variant="circular" className="h-5 w-5" />
      </div>
      <Skeleton className="w-16 h-10 rounded-2xl" />
    </div>
    <Skeleton className="h-12 w-full" />
    <div className="flex gap-2 flex-wrap">
      <Skeleton className="h-12 w-24 rounded-2xl" />
      <Skeleton className="h-12 w-32 rounded-2xl" />
      <Skeleton className="h-12 w-32 rounded-2xl" />
      <Skeleton className="h-12 w-24 rounded-2xl" />
      <Skeleton className="h-12 w-16 rounded-2xl" />
    </div>
    <div className="flex flex-row items-center">
      <Skeleton variant="circular" className="h-3 w-3 mr-2" />
      <Skeleton className="h-8 w-16 mr-5" />
      <Skeleton variant="circular" className="h-3 w-3 mr-2" />
      <Skeleton className="h-8 w-16 mr-5" />
      <Skeleton variant="circular" className="h-3 w-3 mr-2" />
      <Skeleton className="h-8 w-16 mr-5" />
    </div>
  </div>
);
