import { HTMLAttributes, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { Button, Dialog, DropdownMenu, PencilIcon, SpinnerIcon, TrashIcon } from '@kindlyhuman/component-library';
import { CardIcon } from './card_icon';
import { usePaymentMethod } from '../../../hooks/useStripe';
import useAuth from '../../../hooks/useAuth';

export interface PaymentMethodProps extends HTMLAttributes<HTMLDivElement> {
  onUpdateButtonClick?: () => void;
  onSuccessRemovePayment?: () => void;
}

export const PaymentMethod: React.FC<PaymentMethodProps> = ({
  onUpdateButtonClick,
  onSuccessRemovePayment,
  className,
  ...props
}) => {
  const { user } = useAuth();
  const [showRemoveCardModal, setShowRemoveCardModal] = useState<boolean>(false);

  const {
    data: { payment_method } = {},
    isLoading: isPaymentMethodLoading,
    removePaymentMethod,
  } = usePaymentMethod(user?.id);

  const menuItems = [
    ...(onUpdateButtonClick
      ? [
          {
            onClick: onUpdateButtonClick,
            title: 'Update',
            icon: <PencilIcon />,
          },
        ]
      : []),
    {
      onClick: () => setShowRemoveCardModal(true),
      title: 'Remove',
      icon: <TrashIcon />,
    },
  ];

  const deletePaymentMethod = async () => {
    if (user?.id) {
      await removePaymentMethod.mutateAsync(user?.id);

      setShowRemoveCardModal(false);

      if (onSuccessRemovePayment) {
        onSuccessRemovePayment();
      }
    }
  };

  if (isPaymentMethodLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <SpinnerIcon />
      </div>
    );
  }

  return (
    <>
      {payment_method?.card && (
        <div className={twMerge('flex items-center justify-between', className)} {...props}>
          <div className="flex items-center space-x-4">
            <CardIcon brandName={payment_method.card.brand} className="w-12 h-12 md:h-8" />
            <div className="text-dark text-base font-medium leading-normal">*{payment_method.card.last4}</div>
          </div>
          <DropdownMenu items={menuItems} />
        </div>
      )}
      <Dialog
        open={!!showRemoveCardModal}
        onClose={() => setShowRemoveCardModal(false)}
        closeOnOutsideClick={!removePaymentMethod.isLoading}
        className="rounded-2xl backdrop:bg-modalBackdropColor inset-y-1/2 -translate-y-1/2"
      >
        <div className="w-80 h-30 p-8 flex-col justify-center items-center gap-4 inline-flex">
          <div className="flex flex-col">
            <div className="self-stretch text-center text-gray-800 text-xl font-bold font-manrope leading-loose">
              Are you sure?
            </div>
            <div className="text-center text-stone-500 font-manrope">
              Are you sure you want to remove your payment method? You won't be able to have additional call minutes
              without adding it again
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-4 mt-4">
          <Button
            onClick={() => setShowRemoveCardModal(false)}
            variant="secondary"
            disabled={removePaymentMethod.isLoading}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={() => deletePaymentMethod()}
            disabled={removePaymentMethod.isLoading}
            loading={removePaymentMethod.isLoading}
          >
            Remove payment method
          </Button>
        </div>
      </Dialog>
    </>
  );
};
