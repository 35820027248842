import { ExitIcon, Dialog, DialogProps, SpinnerIcon } from '@kindlyhuman/component-library';
import { twMerge } from 'tailwind-merge';
import { PostCallForm, PostCallFormRef, PostCallFormSteps } from './common/PostCallForm';
import { Ref, useState } from 'react';
import { usePostCall } from '../hooks/usePostCall';

export interface PostCallModalProps extends DialogProps {
  postCallId?: number;
  formRef?: Ref<PostCallFormRef>;
}

export const PostCallModal = ({ postCallId, open, onClose, className, formRef, ...rest }: PostCallModalProps) => {
  const [step, setStep] = useState<PostCallFormSteps>(PostCallFormSteps.DESCRIBE);

  const { data: postCall, isLoading } = usePostCall(postCallId!);

  return (
    <Dialog
      className={twMerge(
        'rounded-lg w-full max-w-[620px] shadow-modal p-0 bg-white backdrop:bg-modalBackdropColor',
        className,
      )}
      open={open}
      onClose={onClose}
      {...rest}
    >
      <div
        className={twMerge(
          'flex items-center justify-between p-6 border-b border-b-neutral-200',
          step === PostCallFormSteps.THANK_YOU && 'hidden',
        )}
      >
        <div className="space-y-2">
          <p className="text-2xl font-bold text-dark">Post call</p>
        </div>
        <button onClick={onClose}>
          <ExitIcon color="#22282F" />
        </button>
      </div>
      {isLoading ? (
        <div className="w-full h-144 flex items-center justify-center">
          <SpinnerIcon />
        </div>
      ) : (
        <PostCallForm formRef={formRef} step={step} setStep={setStep} postCall={postCall} onCancel={onClose} />
      )}
    </Dialog>
  );
};
