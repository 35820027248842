import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Dependent, useInviteDependents } from '../../hooks/useInviteDependents';
import { Button, SendIcon, SpinnerIcon, TrashIcon } from '@kindlyhuman/component-library';
import Toast from '../../components/common/PopUpMessage';
import { normalizePhoneNumber } from '../../components/common/normalize-phone-input';
import { MobileMenuHeader, DesktopMenuHeader } from '../../components/MenuHeader';
import { RemoveDependentModal } from '../../components/invite_dependents/remove_dependent_modal';
import { InviteDependentsForm } from '../../components/invite_dependents/invite_dependents_form';
import { DependentCard } from '../../components/invite_dependents/dependent_card';
import useAuth from '../../hooks/useAuth';

const DependentsPage = () => {
  const navigate = useHistory();
  const { user, isLoading: userIsLoading } = useAuth();
  const { data: inviteDependents, isLoading: isInviteDependentsLoading, resendInvite } = useInviteDependents(user?.id);

  const [dependentToRemove, setDependentToRemove] = useState<Dependent | undefined>(undefined);

  const isLoading = userIsLoading || isInviteDependentsLoading;
  const maxNumDependents = user?.caller_role?.active_subscription?.package.dependents_configuration.dependent_count;
  const canUserAddDependents =
    user?.caller_role?.active_subscription?.package.dependents_configuration.members_can_add_dependents &&
    !user.is_dependent;

  const resendInviteDependent = (dependentId: number) => {
    return new Promise((res) =>
      resendInvite.mutate(dependentId, {
        onSuccess: () => {
          Toast.success('Invite email sent again!');
          res(null);
        },
        onError: (error) => {
          // @ts-ignore
          const errorMessage = error?.response?.data?.description;

          if (errorMessage) {
            Toast.error(
              `There was an error resending the invite. ${
                errorMessage ? errorMessage : 'Please contact your administrator.'
              }`,
            );
          } else {
            throw error;
          }

          res(error);
        },
      }),
    );
  };

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <SpinnerIcon />
      </div>
    );
  }

  return (
    <>
      <DesktopMenuHeader />
      <MobileMenuHeader onBack={() => navigate.goBack()} />
      <div className="bg-neutral-100">
        <div className="py-5 px-5 border-b-2">
          <div className="text-gray-800 text-2xl leading-loose font-bold">Invite dependents</div>
        </div>

        {inviteDependents && inviteDependents.length > 0 && (
          <div className="flex flex-col p-4 gap-4 bg-white border-b-2">
            <div className="text-gray-800 text-xl leading-loose font-bold">My dependents</div>
            {inviteDependents.map(({ id, first_name, last_name, email_address, mobile_phone, invite_status }) => (
              <DependentCard
                key={id}
                className="w-full"
                displayName={`${first_name} ${last_name}`}
                emailAddress={email_address}
                phoneNumber={normalizePhoneNumber(mobile_phone.replace('+1', ''))}
                pending={invite_status === 'pending'}
                menuItems={[
                  {
                    onClick: () => {
                      resendInviteDependent(id);
                    },
                    title: 'Resend',
                    icon: <SendIcon />,
                  },
                  {
                    onClick: () => {
                      setDependentToRemove({ id, first_name, last_name } as Dependent);
                    },
                    title: 'Remove',
                    icon: <TrashIcon />,
                  },
                ]}
              />
            ))}
          </div>
        )}
        {inviteDependents?.length !== maxNumDependents && canUserAddDependents && (
          <div className="bg-white">
            {inviteDependents && inviteDependents.length > 0 && (
              <div className="text-gray-800 text-xl leading-loose font-bold p-4">Invite dependent</div>
            )}
            <InviteDependentsForm
              className="border-b-2 px-4 pb-4 mt-6 space-y-4"
              bottomActionButton={({ isDirty, isSubmitting }) => (
                <div className="my-12 flex justify-center">
                  <Button variant="primary" type="submit" disabled={!isDirty || isSubmitting} loading={isSubmitting}>
                    Send invitation
                  </Button>
                </div>
              )}
            />
          </div>
        )}
      </div>
      <RemoveDependentModal
        open={!!dependentToRemove}
        dependent={dependentToRemove}
        onClose={() => setDependentToRemove(undefined)}
      />
    </>
  );
};

export default DependentsPage;
