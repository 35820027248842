import { useEffect, useState } from 'react';
import { SearchFilterOptions } from '../../hooks/useExperiences';
import { useProfileTagGroups } from '../../hooks/useProfileTagGroups';
import { Button, Dialog, ExitIconWithBorder, SelectionChip } from '@kindlyhuman/component-library';
import { User } from '../../hooks/useUser';
import { useMediaQuery } from '../../hooks/useMediaQuery';

interface SearchFiltersModalProps {
  filters: SearchFilterOptions;
  setFilters: React.Dispatch<React.SetStateAction<SearchFilterOptions>>;
  user?: User | null;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  search: (filters?: SearchFilterOptions) => void;
}

export const SearchFiltersModal: React.FC<SearchFiltersModalProps> = ({
  filters,
  setFilters,
  user,
  isOpen,
  setIsOpen,
  onClose,
  search,
}) => {
  const dfMdMedia = useMediaQuery('md');
  const [tempFilters, setTempFilters] = useState<SearchFilterOptions>(filters);
  const changesMade = JSON.stringify(tempFilters) !== JSON.stringify(filters);

  const updateTempFilters = (filterType: keyof SearchFilterOptions, value: (string | number | boolean)[]) => {
    setTempFilters((prev) => ({
      ...prev,
      [filterType]: value,
      page: 1,
    }));
  };

  const handleMultiselectChange = (filterType: keyof SearchFilterOptions, id: string | number | boolean) => {
    let updatedValues: Array<string | number | boolean>;
    const currentValues = (tempFilters[filterType] as Array<string | number | boolean>) || [];

    if (filterType === 'peerType' && typeof id === 'string' && id.startsWith('All')) {
      updatedValues = [];
      updateTempFilters('clientId', []);
    } else if (typeof id === 'string' && id.startsWith('All')) {
      updatedValues = [];
    } else {
      if (currentValues.includes(id)) {
        updatedValues = currentValues.filter((item) => item !== id);
      } else {
        updatedValues = currentValues.filter((item) => typeof item !== 'string' || !item.startsWith('All'));
        updatedValues.push(id);
      }
    }

    updateTempFilters(filterType, updatedValues);
  };

  const applyFilters = () => {
    setFilters(tempFilters);
    search(tempFilters);
  };

  useEffect(() => {
    if (isOpen) {
      setTempFilters(filters);
    }
  }, [filters, isOpen]);

  const content = (
    <div className="flex flex-col h-full">
      <div className="sticky top-0 z-10">
        <Header {...{ filters, setTempFilters, setIsOpen, changesMade, applyFilters, tempFilters }} />
      </div>
      <div className="overflow-y-auto flex-grow">
        <PeerPreferences {...{ tempFilters, handleMultiselectChange }} />
        <div className="bg-neutral-200 h-2"></div>
        <ConnectionPreferences {...{ user, tempFilters, handleMultiselectChange }} />
        {!dfMdMedia && <div className="h-48 bg-white" />}
      </div>
    </div>
  );

  return (
    <Dialog
      open={true}
      className={`w-full p-0 backdrop:bg-modalBackdropColor backdrop-filter backdrop-blur-sm ${
        dfMdMedia ? 'w-2/3 h-1/2 m-auto rounded-2xl' : 'max-w-full h-full mx-0 mb-0 mt-56 rounded-t-2xl bg-neutral-200'
      }`}
      contentClassName="flex h-full"
      onClose={onClose}
    >
      <div className="flex flex-col w-full overflow-y-scroll">{content}</div>
    </Dialog>
  );
};

interface HeaderProps {
  filters: SearchFilterOptions;
  setTempFilters: React.Dispatch<React.SetStateAction<SearchFilterOptions>>;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  changesMade: boolean;
  applyFilters: () => void;
  tempFilters: SearchFilterOptions;
}

const Header: React.FC<HeaderProps> = ({
  filters,
  setTempFilters,
  setIsOpen,
  changesMade,
  applyFilters,
  tempFilters,
}) => {
  return (
    <div className="bg-white flex justify-between items-center border-neutral-200 border-b-2 p-4 pt-2">
      <div className="w-1/3">
        <ExitIconWithBorder
          className="hover:cursor-pointer"
          onClick={() => {
            setTempFilters(filters);
            setIsOpen(false);
          }}
        />
      </div>
      <div className="w-1/3 text-center text-gray-800 text-xl font-bold font-['Manrope'] leading-loose">
        Filter My Pairs
      </div>
      <div className="w-1/3 flex justify-end">
        <Button
          variant="primary"
          type="submit"
          className={`w-24 h-9 px-6 py-3 ${
            changesMade ? 'bg-[#AFFF54] text-[#240089]' : 'bg-neutral-200 text-stone'
          } rounded border-none justify-center items-center gap-1 inline-flex text-center text-base font-bold font-['Manrope'] leading-normal`}
          onClick={() => {
            applyFilters();
            setIsOpen(false);
            localStorage.setItem('searchForSupportFilters', JSON.stringify(tempFilters));
          }}
          disabled={!changesMade}
        >
          Apply
        </Button>
      </div>
    </div>
  );
};

interface PreferencesProps {
  user?: User | null;
  tempFilters: SearchFilterOptions;
  handleMultiselectChange: (filterType: keyof SearchFilterOptions, id: string | number | boolean) => void;
}

const PeerPreferences: React.FC<PreferencesProps> = ({ tempFilters, handleMultiselectChange }) => {
  const { getTagsByKey } = useProfileTagGroups();
  const ageGroupList = ['All Ages', '20s', '30s', '40s', '50s', '60s', '70s+'];
  const genderList = getTagsByKey('GENDER');
  genderList?.unshift({ name: 'All Identities', id: 'All Identities' });

  return (
    <div className="p-4 bg-white">
      <div className="flex flex-col gap-2">
        <div className="text-gray-800 text-lg font-bold font-['Manrope'] leading-normal">Peer Preferences</div>
        <FilterSection subtitle="Limit Pairs to these ages:">
          <FilterOptions
            filterList={ageGroupList}
            queryParam={tempFilters.ageGroups || []}
            handleChange={(id) => handleMultiselectChange('ageGroups', id)}
            preferencesSection="peerPreferences"
          />
        </FilterSection>
        <FilterSection subtitle="Limit Pairs to only these identities:">
          <FilterOptions
            filterList={genderList}
            queryParam={tempFilters.genderTags || []}
            handleChange={(id) => handleMultiselectChange('genderTags', id)}
            preferencesSection="peerPreferences"
          />
        </FilterSection>
      </div>
    </div>
  );
};

const ConnectionPreferences: React.FC<PreferencesProps> = ({ user, tempFilters, handleMultiselectChange }) => {
  const roleList: { name: string; id: string | number }[] = [
    { name: 'All Types', id: 'All Connections' },
    { name: 'Peers', id: 'peer' },
    { name: 'Peer Listeners', id: 'peerlistener' },
  ];
  const clientInfoArray = [{ name: `${user?.client_name} Peers`, id: user?.client_id || '' }];
  const availableNowArray = [
    { name: 'Available Anytime', id: 'All Availablities' },
    { name: 'Available Now', id: 'true' },
  ];
  return (
    <div className="p-4 bg-white">
      <div className="flex flex-col gap-2">
        <div className="text-gray-800 text-lg font-bold font-['Manrope'] leading-normal">Connection Preferences</div>
        <FilterSection subtitle="Limit Pairs to these types of connections:">
          <div className="flex flex-wrap gap-2">
            <FilterOptions
              filterList={roleList}
              queryParam={tempFilters.peerType || []}
              handleChange={(id) => handleMultiselectChange('peerType', id)}
              preferencesSection="connectionPreferences"
              tempFilters={tempFilters}
              // TODO make sure that works
              hasClientPeers={Boolean(user) && user?.has_peers}
            />
            {user && user.has_peers && (
              <FilterOptions
                filterList={clientInfoArray}
                queryParam={tempFilters.clientId || undefined}
                handleChange={(id) => handleMultiselectChange('clientId', id)}
                preferencesSection="connectionPreferences"
              />
            )}
          </div>
        </FilterSection>
        <FilterSection subtitle="Limit Pairs by Availability:">
          <FilterOptions
            filterList={availableNowArray}
            queryParam={tempFilters.availableNow || []}
            handleChange={(id) => handleMultiselectChange('availableNow', id)}
            preferencesSection="connectionPreferences"
          />
        </FilterSection>
      </div>
    </div>
  );
};

interface FilterOptionsProps {
  filterList?: string[] | { name: string; id: number | string }[];
  queryParam?: Array<string | number> | number | string;
  handleChange: (id: string | number) => void;
  preferencesSection: string;
  tempFilters?: SearchFilterOptions;
  hasClientPeers?: boolean;
}

const FilterOptions: React.FC<FilterOptionsProps> = ({
  filterList,
  queryParam,
  handleChange,
  preferencesSection,
  tempFilters,
  hasClientPeers = false,
}) => (
  <div
    className={`flex gap-2 ${tempFilters ? 'flex-nowrap pt-4' : 'flex-wrap py-4'} ${tempFilters && hasClientPeers ? '-mb-3' : 'pb-4'}`}
  >
    {filterList?.map((item) => {
      const label = typeof item === 'string' ? item : item.name;
      const value = typeof item === 'string' ? item : item.id;

      const isSelected =
        // The regular check — if the value is in the queryParam, it's selected
        (Array.isArray(queryParam) && queryParam.includes(value)) ||
        // The check for the "All" buttons — IF the value is "All" AND the queryParam is empty, it's selected
        (typeof value === 'string' &&
          value.startsWith('All') &&
          (!queryParam || (Array.isArray(queryParam) && queryParam.length === 0)) &&
          // This is for the unique case of the peerType and clienntId filters. They're treated as one section, although they're separate query params.
          // So, only for peerType do we send tempFilters, so that it may be aware of the state of clientId.
          (tempFilters === undefined ||
            // Annoyingly, even though it will only ever consist of one number, we have to treat the clientId filter as an array due to the way handleMultiselectChange hadnles different types of filters.
            // If tempFilters exist, clientId must be empty for the "All" button to be selected.
            (Array.isArray(tempFilters?.clientId) && tempFilters?.clientId.length === 0)));

      return (
        <button
          key={value}
          className={`w-fit px-4 py-2 border border-opacity-30 bg-opacity-10 font-medium font-manrope h-10 rounded-[100px] text-sm leading-5 whitespace-nowrap
        ${
          isSelected && preferencesSection === 'peerPreferences'
            ? 'bg-blue bg-opacity-20 border border-blue border-opacity-30'
            : ''
        }
        ${
          isSelected && preferencesSection === 'connectionPreferences'
            ? 'bg-purple-500 bg-opacity-30 border border-purple-500 border-opacity-50'
            : ''
        }`}
          onClick={() => handleChange(value)}
        >
          {label}
        </button>
      );
    })}
  </div>
);

interface FilterSectionProps {
  subtitle: string;
  children: React.ReactNode;
}

const FilterSection: React.FC<FilterSectionProps> = ({ subtitle, children }) => {
  return (
    <div className="">
      <div className="text-gray-800 text-base font-normal font-['Manrope'] leading-tight">{subtitle}</div>
      {children}
    </div>
  );
};
