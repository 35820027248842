import { AffiliationTile } from '../../../components/mwa-3.5-redesign/affiliation-tile';
import TileContainer from '../../../components/mwa-3.5-redesign/tile-container';
import { Resource } from '../../../hooks/useResources';
import { Client } from '../../../hooks/useUser';

interface AffilationsSectionProps {
  resource?: Resource;
  client?: Client;
}

const AffilationsSection: React.FC<AffilationsSectionProps> = ({ resource, client }) => {
  return (
    <TileContainer
      title={'Affiliations'}
      childrenTiles={
        <>
          {resource && (resource.wide_image_url || resource.image_url) && (
            <AffiliationTile
              path={`resource/${resource.id}_${resource.name}`}
              image={resource.wide_image_url || resource.image_url}
              name={resource.name}
              caption={'Certified Peer'}
            />
          )}
          {client && client.comms_logo_file_url && (
            <AffiliationTile image={client.comms_logo_file_url} name={client.name} caption={'Certified Peer'} />
          )}
        </>
      }
    />
  );
};

export default AffilationsSection;
