import { Redirect, Route, Switch } from 'react-router';
import { ROUTE_PATH } from './route-paths';
import AdminRedirectPage from '../containers/sb-redirect-page/sb-redirect-page';

const adminRoutes = [
  {
    path: ROUTE_PATH.ADMIN_REDIRECT,
    component: AdminRedirectPage,
  },
];

export const AdminRouter = () => {
  console.log('in admin router');
  return (
    <Switch>
      {adminRoutes.map(({ path, component }) => (
        <Route exact path={path} component={component} />
      ))}
      <Route path="*" render={() => <Redirect to={ROUTE_PATH.ADMIN_REDIRECT} />} />
    </Switch>
  );
};
