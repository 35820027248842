import { AboutUs } from '../../assets';
import { AboutUsText } from '../../components/common/AboutUsText';
import { LogoShortIcon } from '@kindlyhuman/component-library';
import { MobileMenuHeader, DesktopMenuHeader } from '../../components/MenuHeader';
import { ROUTE_PATH } from '../../routes/route-paths';
import { useHistory } from 'react-router-dom';

const AboutUsPage = () => {
  const navigate = useHistory();

  return (
    <div className="pb-10 md:pb-0">
      <MobileMenuHeader onBack={() => navigate.push(ROUTE_PATH.HOME)} />
      <DesktopMenuHeader />
      <div className="bg-neutral-700 bg-opacity-5 p-4 space-y-2 md:hidden">
        <div className="text-gray-800 text-2xl font-bold">About us</div>
        <div className="text-stone-500 text-sm font-medium">We’re all human. Be kindly.</div>
      </div>
      <div
        className="
          mx-auto grid grid-cols-1 max-w-3xl
          md:grid-cols-2 md:gap-13 md:h-[calc(100vh-64px)] md:items-center md:max-w-7xl
        "
      >
        <img className="py-3 px-4 order-1 md:order-2" src={AboutUs} alt="about us" />
        <div className="px-4 order-2 md:order-1">
          <AboutUsText className="pt-3 pb-12" />
          <LogoShortIcon className="mx-auto md:mx-0" />
        </div>
      </div>
    </div>
  );
};

export default AboutUsPage;
