import { CircledArrowRightIcon } from '@kindlyhuman/component-library';
import { Link } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { ROUTE_PATH } from '../../routes/route-paths';

export const LoginButton: React.FC = () => {
  const { keycloak, isKeycloakEnabled } = useAuth();
  const loginButton = isKeycloakEnabled ? (
    <div
      data-testid="login-button"
      className="flex items-center gap-1 text-primary text-sm font-bold leading-tight cursor-pointer"
      onClick={() => keycloak?.login()}
    >
      Log in
      <CircledArrowRightIcon />
    </div>
  ) : (
    <Link
      data-testid="login-button"
      className="flex items-center gap-1 text-primary text-sm font-bold leading-tight"
      to={ROUTE_PATH.LOGIN}
    >
      Log in
      <CircledArrowRightIcon />
    </Link>
  );

  return (
    <div className="flex justify-center items-center gap-2 mt-4">
      <div className="text-gray-800 text-sm font-normal leading-tight">Already have an account?</div>
      {loginButton}
    </div>
  );
};
