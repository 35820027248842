import React, { createContext, useContext, useState, ReactNode } from 'react';
import { settingsModalAccordionItems } from '../components/settings_modal';

interface SettingsModalContextProps {
  isSettingModalOpen: boolean;
  initialSettingModalItem: settingsModalAccordionItems | undefined;
  openSettingModal: (item: settingsModalAccordionItems) => void;
  closeSettingModal: () => void;
}

const SettingsModalContext = createContext<SettingsModalContextProps | undefined>(undefined);

export const SettingsModalProvider = ({ children }: { children: ReactNode }) => {
  const [isSettingModalOpen, setIsSettingModalOpen] = useState<boolean>(false);
  const [initialSettingModalItem, setInitialSettingModalItem] = useState<settingsModalAccordionItems | undefined>(
    undefined,
  );

  const openSettingModal = (item: settingsModalAccordionItems) => {
    setIsSettingModalOpen(true);
    setInitialSettingModalItem(item);
  };

  const closeSettingModal = () => {
    setIsSettingModalOpen(false);
    setInitialSettingModalItem(undefined);
  };

  return (
    <SettingsModalContext.Provider
      value={{ isSettingModalOpen, initialSettingModalItem, openSettingModal, closeSettingModal }}
    >
      {children}
    </SettingsModalContext.Provider>
  );
};

export const useSettingsModal = () => {
  const context = useContext(SettingsModalContext);
  if (!context) {
    throw new Error('useSettingsModal must be used within a SettingsModalProvider');
  }
  return context;
};
