import { useState, useRef, useMemo, useImperativeHandle, forwardRef, useEffect } from 'react';
import { twMerge } from 'tailwind-merge';

import { LeftArrowWithCircle } from '@kindlyhuman/component-library';
import { useMediaQuery } from '../../hooks/useMediaQuery';

interface HorizontalScrollSectionProps {
  children?: React.ReactNode;
  center?: boolean;
  className?: string;
  showNavigationButton?: boolean | null;
  isLoading?: boolean;
}

export interface HorizontalScrollSectionRef {
  resetScrollPosition: () => void;
}

export const HorizontalScrollSection = forwardRef<HorizontalScrollSectionRef, HorizontalScrollSectionProps>(
  ({ children, className, center = false, showNavigationButton, isLoading }, ref) => {
    const [position, setPosition] = useState<number>(0);
    const horizontalScrollSectionRef = useRef<HTMLDivElement>(null);

    useImperativeHandle(ref, () => ({
      resetScrollPosition: () => {
        if (isLoading) {
          setPosition(0);
        }
      },
    }));

    const dfMdMedia = useMediaQuery('md');

    const { maxPosition, transformValue } = useMemo(() => {
      const horizontalScrollEl = horizontalScrollSectionRef.current;

      if (horizontalScrollEl) {
        const blockCssStyles = window.getComputedStyle(horizontalScrollEl);
        const childCount = horizontalScrollEl.childElementCount ?? 0;
        const childWidth = (horizontalScrollEl.childNodes?.[0] as Element)?.clientWidth ?? 0;
        const blockPaddingLeft = parseFloat(blockCssStyles.getPropertyValue('padding-left')) ?? 0;
        const blockPaddingRight = parseFloat(blockCssStyles.getPropertyValue('padding-right')) ?? 0;
        const blockChildGap =
          (horizontalScrollEl?.scrollWidth - childCount * childWidth - blockPaddingLeft) / (childCount - 1) ?? 0;
        const blockWidth = horizontalScrollEl.clientWidth ?? 0;
        const maxTransformPosition = horizontalScrollEl?.scrollWidth - blockWidth + blockPaddingLeft ?? 0;

        return {
          maxPosition:
            childCount - Math.ceil((blockWidth - blockPaddingLeft - blockPaddingRight) / (childWidth + blockChildGap)),
          transformValue: dfMdMedia
            ? `translateX(-${Math.min(position * (childWidth + blockChildGap), maxTransformPosition)}px)`
            : '',
        };
      }

      return {
        maxPosition: 0,
        transformValue: '',
      };
    }, [position, dfMdMedia, horizontalScrollSectionRef]);

    useEffect(() => {
      if (isLoading) {
        setPosition(0);
      }
    }, [isLoading]);

    return (
      <>
        <div className="relative gap-2 overflow-hidden">
          <div
            ref={horizontalScrollSectionRef}
            className={twMerge(
              'flex flex-row py-2 justify-start gap-x-3 transition-all',
              center ? 'items-center' : 'items-start',
              (!showNavigationButton || !dfMdMedia) && 'overflow-x-scroll',
              className,
            )}
            style={{
              scrollbarWidth: 'none',
              transform: transformValue,
            }}
          >
            {children}
          </div>
        </div>
        {showNavigationButton && dfMdMedia && (
          <>
            <button
              className={twMerge(
                'absolute left-0 top-1/2 transform -translate-x-full cursor-pointer z-10 rotate-180',
                !position && 'opacity-50',
              )}
              onClick={scrollLeft}
              disabled={!position}
            >
              <LeftArrowWithCircle />
            </button>
            <button
              className={twMerge(
                'absolute right-0 top-1/2 transform translate-x-full cursor-pointer z-10',
                position > maxPosition && 'opacity-50',
              )}
              onClick={scrollRight}
              disabled={position > maxPosition}
            >
              <LeftArrowWithCircle />
            </button>
          </>
        )}
      </>
    );

    function scrollLeft() {
      setPosition((currentValue) => currentValue - 1);
    }

    function scrollRight() {
      setPosition((currentValue) => currentValue + 1);
    }
  },
);
