import { useFormContext } from 'react-hook-form';
import { Subheader } from '..';
import { PostCall } from '../../../../hooks/usePostCall';
import { twMerge } from 'tailwind-merge';
import { Textarea } from '@kindlyhuman/component-library';

export interface FeedbackStepProps {
  postCall?: PostCall;
  className?: string;
}

export const FeedbackStep: React.FC<FeedbackStepProps> = ({ postCall, className }) => {
  const { register } = useFormContext();

  return (
    <div className={twMerge('space-y-5', className)}>
      <Subheader
        className="px-4 md:px-0"
        question={
          <>
            Is there any <span className="font-bold">feedback</span> you’d like to share with Kindly Human?
          </>
        }
        postCall={postCall}
      />
      <Textarea
        {...register('feedback')}
        className="mt-4 max-md:rounded-none max-md:border-l-0 max-md:border-r-0"
        placeholder="Share your thoughts here"
        minRows={7}
        maxRows={12}
      />
    </div>
  );
};
