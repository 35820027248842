import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { axiosGet, axiosPost } from '../api/axios-handler';

export interface Reflection {
  check_in_question: string;
  engagement_type: string;
  id: number;
  incentivized: boolean;
  incentivized_pto: boolean;
  morphii: string[];
  morphii_metadata: MorphiiMetadaum[];
  partner_code_id: any;
  primary_engagement_id: any;
}

interface MorphiiMetadaum {
  delighted: Delighted;
  disappointed: Disappointed;
  id: string;
  label: string;
}

interface Delighted {
  polarity: number;
}

interface Disappointed {
  polarity: number;
}

const getCurrentReflection = async () => {
  return await axiosGet('/reflections/current', {}).then((response) => response.data);
};

const postReflection = async (id: number, engagementScore: number) => {
  return await axiosPost(`/reflections/${id}`, { engagement_score: engagementScore }).then((response) => response.data);
};

export const useReflectionDismissMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async (reflectionId?: number) => {
      if (!reflectionId) {
        return;
      }
      localStorage.setItem('dismissed_reflection', reflectionId.toString());
      return true;
    },
    {
      onSuccess: () => {
        queryClient.removeQueries(['reflections']);
      },
    },
  );
};

export const useReflectionMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(
    async ({ reflectionId, engagementScore }: { reflectionId: number; engagementScore: number }) => {
      return postReflection(reflectionId, engagementScore);
    },
    {
      onSuccess: (data: any) => {
        localStorage.setItem('dismissed_reflection', data.id);
        queryClient.removeQueries(['reflections']);
      },
    },
  );
};

export const useReflections = () => {
  return useQuery<Reflection | null>(
    ['reflections'],
    async () => {
      const reflection = await getCurrentReflection();
      if (reflection?.id) {
        const dismissed = Number(localStorage.getItem('dismissed_reflection') || 0);
        if (reflection.id === dismissed) {
          return null;
        }
      }
      return reflection;
    },
    {
      staleTime: Infinity,
      // default response is typically a 404, so no retries
      retry: false,
    },
  );
};
