import { TagPill } from '../../../components/mwa-3.5-redesign/tag-pill';
import { Avatar } from '@kindlyhuman/component-library';
import TileContainer from '../../../components/mwa-3.5-redesign/tile-container';
import { SearchFilterOptions } from '../../../hooks/useExperiences';

interface TopSearchesProps {
  handleClose?: () => void;
  setSearchFilters: React.Dispatch<React.SetStateAction<SearchFilterOptions>>;
  handleSearch: (text: string) => void;
}

// Temp data structure; this will eventually be read from the server
interface TopSearchData {
  name: string;
  listener_image_paths: string[];
}

const TOP_SEARCHES_DATA: TopSearchData[] = [
  {
    name: 'Relationships',
    listener_image_paths: [
      'https://app.listenersoncall.com/avatars/55e797d4-e227-4be2-984e-5e13b6787430.jpg',
      'https://app.listenersoncall.com/avatars/ecdb625f-9804-4991-bd4c-46b4b446e496.jpg',
      'https://app.listenersoncall.com/avatars/1caac7d7-d76f-4574-bf49-f8caf17845bc.jpg',
    ],
  },
  {
    name: 'Work Issues',
    listener_image_paths: [
      'https://app.listenersoncall.com/avatars/25f2064c-3c39-4ec8-b7df-45e414780121.jpg',
      'https://app.listenersoncall.com/avatars/e33611e6-ba09-471c-9a7f-c4f947ceb8e6.jpg',
      'https://app.listenersoncall.com/avatars/ad2b95b8-8c20-4ae4-96b2-f3d79cfedde5.jpg',
    ],
  },
  {
    name: 'Stress',
    listener_image_paths: [
      'https://app.listenersoncall.com/avatars/2937d022-942f-49bd-a202-3107145d07bb.jpg',
      'https://app.listenersoncall.com/avatars/e51f6f19-5c1a-48d0-bda2-147dfaa73342.jpg',
      'https://app.listenersoncall.com/avatars/9ec7e39b-1af3-4efd-b1d1-c6988ff0030a.jpg',
    ],
  },
];

const TopSearchTile: React.FunctionComponent<
  TopSearchData & {
    handleSearch: (text: string) => void;
  }
> = ({ name, listener_image_paths, handleSearch }) => {
  const handleClick = () => {
    window.scrollTo(0, 0);
    handleSearch(name);
  };
  return (
    <>
      <div onClick={handleClick} className="py-1 pl-2 pr-1 rounded-[10px] flex flex-row bg-white cursor-pointer">
        <div className="mr-2 flex flex-col">
          <div className="py-2 text-sm font-thin opacity-70 font-texta leading-normal">Looking for support on:</div>
          <TagPill name={name} className="mb-2 w-fit bg-[#25008a] text-white" />
        </div>
        {listener_image_paths.map((path) => (
          <Avatar variant="rounderSmall" image={path} className="m-1" />
        ))}
      </div>
    </>
  );
};

export const TopSearches: React.FunctionComponent<TopSearchesProps> = ({ handleClose, handleSearch }) => {
  // shuffle the static data every few minutes to make it seem dynamic
  const shuffled_search_data = new Date().getMinutes() % 3 == 0 ? TOP_SEARCHES_DATA : TOP_SEARCHES_DATA.toReversed();
  return (
    <TileContainer
      title="Top Searches"
      subtitle="Members like you are searching for support."
      bgColor={'bg-[#005DFE] bg-opacity-10'}
      childrenTiles={shuffled_search_data.map((topSearch) => (
        <TopSearchTile
          handleSearch={handleSearch}
          name={topSearch.name}
          listener_image_paths={topSearch.listener_image_paths}
        />
      ))}
      handleClose={handleClose}
    />
  );
};
