import { useQuery } from '@tanstack/react-query';
import { axiosGetV3 } from '../api/axios-handler';
import { LogoSquare } from '../assets';
import { analytics, EventTypes } from '@kindlyhuman/component-library';
import { useExternalProviderPlan } from './useExternalProviderPlans';
import useAuth from './useAuth';

export const resourceKeys = {
  all: ['resources'] as const,
  resource: ['resource'] as const,
};

export interface Resource {
  client_id: number | null;
  description: string;
  id: number;
  image_url: string;
  name: string;
  notes: string;
  phone_number: string;
  sort_weight: number;
  status: string;
  url: string;
  url_description: string;
  subject_areas: number[] | null;
  challenge_areas: number[] | null;
  long_description?: string;
  tag_names: string[];
  wide_image_url: string;
}

export interface ResourcesResponse {
  data: Resource[];
  count: number;
}

export const useRecommendedResourcesTextSearch = ({
  limit = 10,
  page = undefined,
  query,
  enabled = true,
  onComplete,
}: {
  limit: number;
  page?: number;
  query?: string;
  enabled?: boolean;
  onComplete?: () => void;
}) => {
  return useQuery<ResourcesResponse>(
    ['textSearchResources', query, limit, page],
    () =>
      axiosGetV3('/resources/search', {
        query,
        limit,
        page,
      }).then((response) => response.data),
    {
      enabled,
      staleTime: Infinity,
      onSuccess: onComplete,
      select: (data: ResourcesResponse) => {
        return {
          ...data,
          data: data.data.map((resource) => {
            resource.image_url = resource.image_url || LogoSquare;
            return resource;
          }),
        };
      },
    },
  );
};

const getResourceByID = async (id: number): Promise<Resource> => {
  analytics.trackEvent(EventTypes.VISIT_RESOURCE, id);
  return await axiosGetV3(`/resources/${id}`, {}).then((resourceResponse) => resourceResponse.data);
};

export const useResourceByID = (id?: number) => {
  return useQuery<Resource>(
    [resourceKeys.resource, id],
    () => (id ? getResourceByID(id) : Promise.resolve({} as Resource)),
    {
      staleTime: Infinity,
      select: (data: Resource) => {
        if (data.id) {
          return { ...data, image_url: data.image_url || LogoSquare };
        }
        return data;
      },
      enabled: !!id,
    },
  );
};

interface UseResourcesParams {
  clientId?: number;
  showGlobal?: boolean;
}

export const useResources = ({ clientId, showGlobal }: UseResourcesParams) => {
  return useQuery<ResourcesResponse>(
    ['resources', clientId],
    () => axiosGetV3('/resources', { client_id: clientId, show_global: showGlobal }).then((response) => response.data),
    {
      staleTime: Infinity,
      select: (data: ResourcesResponse) => {
        return {
          ...data,
          data: data.data.map((resource) => {
            resource.image_url = resource.image_url || LogoSquare;
            return resource;
          }),
        };
      },
    },
  );
};

export const useHasMyPrograms = () => {
  const { user } = useAuth();
  const { data: groupLevelResources } = useResources({ clientId: user?.client_id });
  const { data: providerPlans } = useExternalProviderPlan();

  const hasGroupResources = groupLevelResources && groupLevelResources.count > 0;
  const providerPlan =
    providerPlans?.find((plan) => plan.id === user?.caller_role.active_subscription?.package.external_plan_id) ?? null;
  const hasProviderPlan = providerPlan !== null;

  const hasMyPrograms = hasGroupResources || hasProviderPlan;

  return { hasMyPrograms, hasGroupResources, hasProviderPlan };
};
