import { useHistory } from 'react-router-dom';

import { useResources } from '../../hooks/useResources';

import { ROUTE_PATH } from '../../routes/route-paths';

import { MobileMenuHeader, DesktopMenuHeader } from '../../components/MenuHeader';
import { Spinner } from '../../components/common';
import { ResourceCard, ResourceCardProps } from './resource-components/resource-card';
import { BackgroundStripe } from '../../components/mwa-3.5-redesign/bg-stripe';

export const GlobalResourcePage = () => {
  const { data: globalResources, isLoading: globalResourcesLoading } = useResources({ showGlobal: true });
  const navigate = useHistory();

  const displayedResources = globalResources?.data;

  if (globalResourcesLoading) return <Spinner />;

  return (
    <>
      <div className="w-full h-screen flex flex-col overflow-hidden bg-white bg-opacity-5">
        <DesktopMenuHeader />
        <MobileMenuHeader onBack={() => navigate.goBack()} />
        <div className="w-full overflow-y-auto">
          <div className="w-full mx-auto py-5 md:w-auto md:max-w-7xl md:px-6">
            <div className="px-4 py-2 pb-10 md:px-0">
              <p className="text-[#222833] text-2xl font-semibold font-['Texta'] leading-normal flex flex-nowrap">
                Resources
              </p>
            </div>
            <div className="mx-auto justify-center grid gap-4 md:grid-cols-2 lg:grid-cols-3 md:gap-6">
              {displayedResources?.map((resource) => (
                <ResourceCard
                  key={`${ROUTE_PATH.RESOURCE}/${resource.id}_${resource.name.replaceAll(' ', '-')}`}
                  isGlobal={true}
                  {...resource}
                  link={`${ROUTE_PATH.RESOURCE}/${resource.id}_${resource.name.replaceAll(' ', '-')}`}
                />
              ))}
            </div>
          </div>
        </div>
        <BackgroundStripe />
      </div>
    </>
  );
};
