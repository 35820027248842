import { UseQueryResult } from '@tanstack/react-query';
import TileContainer from '../../../components/mwa-3.5-redesign/tile-container';
import { AvailableListenersResponse } from '../../../hooks/usePeers';
import { ROUTE_PATH } from '../../../routes/route-paths';
import {
  ConnectNowPeerTile,
  ConnectNowPeerTileSkeleton,
} from '../../../components/mwa-3.5-redesign/connect-now-peer-tile';

export interface TopListenersProps {
  topListeners: UseQueryResult<AvailableListenersResponse, unknown>;
}

export const TopListeners = ({ topListeners }: TopListenersProps) => {
  return (
    <TileContainer
      title={'Top Listeners'}
      subtitle="Listeners you can connect with"
      isLoading={topListeners.isLoading}
      childrenTiles={
        <>
          {topListeners?.data?.data
            ?.slice(0, 3)
            .map((peer) => <ConnectNowPeerTile key={peer.listener_role_id} peer={peer} />)}
        </>
      }
      skeletonTiles={[1, 2].map((int) => (
        <ConnectNowPeerTileSkeleton key={int} />
      ))}
      bgColor={'bg-[#2ee5da]/40'}
      dataTestId={'connect-now-container'}
      redirectText="See more Listeners »"
      redirectLink={ROUTE_PATH.AVAILABLE_LISTENERS}
      titleSkeletons={topListeners.isLoading}
    />
  );
};
