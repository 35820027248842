import { useHistory } from 'react-router-dom';
import { MobileMenuHeader, DesktopMenuHeader } from '../../../components/MenuHeader';
import { PLUSPediatric } from '../../../assets';
import { Spinner } from '../../../components/common';
import { ResourceDetailView } from '../resource-detail';
import { BackgroundStripe } from '../../../components/mwa-3.5-redesign/bg-stripe';
import { ProviderDescription, useProviderDetails } from '../resource-components/useProviderDetails';

export const PediatricPage: React.FC = () => {
  // As of now, the only provider that has a pediatric benefit is Recuro
  const navigate = useHistory();
  const { clientLogoUrl, isLoading, partnerResourceConfig, phoneNumber, providerName, url, urlDescription } =
    useProviderDetails();

  const name = '+Pediatric Behavioral Health';
  const description = (
    <ProviderDescription
      description="Your benefit includes virtual access to comprehensive behavioral and mental health support. From school pressures,
      to external stressors, social anxieties, and self-esteem issues – families are dealing with a lot. Pediatric
      Behavioral Health provides coaching programs to help with everyday challenges, counseling for common childhood
      conditions, and a digital platform for support on-the-go to help families thrive. Parental support through
      self-guided and coach-supported skills training is also available for parents and caregivers."
      commonUses={['School Pressure', 'Stress', 'Anxiety', 'Bullying', 'Confidence', 'Self-esteem', 'And more']}
      onlineAccessText="Click the link above to schedule a visit online."
    />
  );

  if (isLoading) return <Spinner />;

  return (
    <div className="w-full min-h-screen overflow-auto bg-cover bg-neutral-700 bg-opacity-5 pb-20">
      <DesktopMenuHeader />
      <MobileMenuHeader onBack={() => navigate.goBack()} />
      <ResourceDetailView
        name={name}
        providerName={providerName}
        imageUrl={PLUSPediatric}
        ctaButtonText={'Schedule Visit Online'}
        url={url}
        urlDescription={urlDescription}
        callButtonText={'Schedule Visit By Phone'}
        phoneNumber={phoneNumber}
        tagNames={['Behavioral Health']}
        description={description}
        clientLogoUrl={clientLogoUrl}
        partnerResourceConfig={partnerResourceConfig}
      />
      <BackgroundStripe />
    </div>
  );
};
