import { HTMLAttributes, useMemo } from 'react';

import {
  CardIcon,
  AmexCardIcon,
  DinersCardIcon,
  DiscoverCardIcon,
  JcbCardIcon,
  MastercardCardIcon,
  UnionpayCardIcon,
  VisaCardIcon,
} from '@kindlyhuman/component-library';

import { cardBrandType } from '../../../hooks/useStripe';

export interface CardIconProps extends HTMLAttributes<HTMLImageElement> {
  brandName: cardBrandType;
}

const CardIconComponent: React.FC<CardIconProps> = ({ brandName, ...props }) => {
  const CardIconElement = useMemo(() => {
    const brandsIcons: Partial<Record<cardBrandType, any>> = {
      amex: AmexCardIcon,
      diners: DinersCardIcon,
      discover: DiscoverCardIcon,
      jcb: JcbCardIcon,
      mastercard: MastercardCardIcon,
      unionpay: UnionpayCardIcon,
      visa: VisaCardIcon,
      unknown: CardIcon,
      // eftpos_au: '',
    };

    return brandsIcons[brandName] || brandsIcons.unknown;
  }, [brandName]);

  return <CardIconElement {...props} />;
};

export { CardIconComponent as CardIcon };
