import { useEffect, useRef, useState } from 'react';
import TileContainer from '../../../components/mwa-3.5-redesign/tile-container';
import { useMediaQuery } from '../../../hooks/useMediaQuery';
import DOMPurify from 'dompurify';

const AboutMeSection: React.FC<{
  aboutMe: string;
}> = ({ aboutMe }) => {
  const dfMdMedia = useMediaQuery('md');
  const [showMore, setShowMore] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const textRef = useRef<HTMLDivElement>(null);
  const cloneRef = useRef<HTMLDivElement>(null);
  const purifiedAboutMe = DOMPurify.sanitize(aboutMe, { ALLOWED_TAGS: ['br'] });

  useEffect(() => {
    if (textRef.current && cloneRef.current) {
      const textHeight = textRef.current.clientHeight;
      const cloneHeight = cloneRef.current.clientHeight;
      setIsTruncated(cloneHeight > textHeight);
    }
  }, [aboutMe, dfMdMedia]);

  return (
    <TileContainer
      title="About Me"
      childrenTiles={
        <>
          {' '}
          <div
            ref={textRef}
            className={`${!showMore && !dfMdMedia ? 'line-clamp-7' : ''}`}
            dangerouslySetInnerHTML={{ __html: purifiedAboutMe }}
          />
          {/* this cloneRef let's us compare the non line-clamped about me height to determine whether or not to show the button */}
          <div
            ref={cloneRef}
            className="absolute top-0 left-0 invisible h-auto"
            style={{ whiteSpace: 'pre-wrap' }}
            dangerouslySetInnerHTML={{ __html: purifiedAboutMe }}
          />
          {!dfMdMedia && isTruncated && (
            <div className="flex justify-end py-3">
              <div
                className="text-md font-medium font-textaMedium antialiased leading-normal cursor-pointer"
                onClick={() => setShowMore(!showMore)}
              >
                {showMore ? 'Less' : 'More'} »
              </div>
            </div>
          )}
        </>
      }
      bgColor="bg-transparent"
      transparent
    />
  );
};

export default AboutMeSection;
