import { Redirect, Route } from 'react-router';
import { PwaRedirectPage } from '../containers';
import { ROUTE_PATH } from './route-paths';
import { Switch } from 'react-router-dom';

const peerRoutes = [
  {
    path: ROUTE_PATH.PWA_REDIRECT_PAGE,
    component: PwaRedirectPage,
  },
];

export const PeerRouter = () => {
  console.log('in peer router');
  return (
    <Switch>
      {peerRoutes.map(({ path, component }) => (
        <Route exact path={path} component={component} />
      ))}
      <Route path="*" render={() => <Redirect to={ROUTE_PATH.PWA_REDIRECT_PAGE} />} />
    </Switch>
  );
};
