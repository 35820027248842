import { useAllCalls } from '../../../../hooks/useCalls';
import { NoCallsCard } from '../../../common/call_history/NoCallsCard';
import { HistoryCallCard, HistoryCallCardSkeleton } from '../../../common/call_history/HistoryCallCard';
import { FormBlock } from '../../../common/form_block';
import { TabHeader } from '../tab_header';
import useAuth from '../../../../hooks/useAuth';

export const CallHistoryTab = () => {
  const { user, isLoading: userIsLoading } = useAuth();
  const { data: calls, isLoading: isCallsLoading } = useAllCalls(user?.caller_role_id);

  return (
    <>
      <TabHeader header="Call history" />
      <div className="space-y-2">
        {!(userIsLoading || isCallsLoading) ? (
          calls?.length ? (
            calls?.map((call) => (
              <FormBlock key={call.id}>
                <HistoryCallCard call={call} />
              </FormBlock>
            ))
          ) : (
            <NoCallsCard className="w-[300px] mx-auto mt-14" />
          )
        ) : (
          [...Array(5).keys()].map((index) => (
            <FormBlock key={index}>
              <HistoryCallCardSkeleton />
            </FormBlock>
          ))
        )}
      </div>
    </>
  );
};
