import { useMutation } from '@tanstack/react-query';
import { axiosPostV3 } from '../api/axios-handler';

export type HubspotEventType =
  | 'call_success'
  | 'click_client_resource'
  | 'click_client_resource_link'
  | 'click_client_resource_call_button'
  | 'click_global_resource'
  | 'click_global_resource_call_button'
  | 'click_global_resource_link'
  | 'click_provider'
  | 'click_provider_link'
  | 'click_provider_call_button'
  | 'login'
  | 'onboarding_completed'
  | 'onboarding_started'
  | 'play_client_video'
  | 'play_experience'
  | 'schedule_call'
  | 'submit_query';

export interface HubspotEvent {
  event: HubspotEventType;
  properties?: {
    [key: string]: string | number | boolean;
  };
}

const postHobspotEvent = async (event: HubspotEvent) => {
  return axiosPostV3('/events/hubspot', event);
};

export const useHubspotEventMutation = () => {
  return useMutation(postHobspotEvent);
};
