import React from 'react';

type props = {
  lable: string;
  subLabel?: string;
  inputValue?: any;
  inputChange?: (data: any) => void;
  type: string;
  className?: string;
  labelClass?: string;
  isAutoFocus?: boolean;
  name?: string;
  error?: any;
  disabled?: boolean;
  id?: string;
  register?: any;
};

const InputBox: React.FunctionComponent<props> = ({
  lable,
  subLabel,
  inputValue,
  type,
  className,
  labelClass,
  isAutoFocus,
  name,
  error,
  inputChange,
  disabled,
  id,
  register,
  ...child
}): JSX.Element => {
  return (
    <div {...child} className={className}>
      <div className={subLabel ? ' flex justify-between ' : ''}>
        <label className={`${error ? 'input-label-error' : 'input-label'}`}>{lable}</label>
        {subLabel ? (
          <label className={`${labelClass ? labelClass : 'text-2xs'} text-lightest-gray`}>{subLabel}</label>
        ) : null}
      </div>
      <input
        id={id}
        type={type}
        disabled={disabled ? disabled : false}
        className={`w-full ${error ? 'input-error' : 'input'} ${disabled && 'bg-white cursor-not-allowed'}`}
        autoFocus={isAutoFocus}
        value={inputValue}
        {...register}
        onChange={(e) => {
          inputChange && inputChange(type === 'password' ? e.target.value.trim() : e.target.value);
          register && register.onChange(e);
        }}
      />
      {error && <p className="text-red text-xs font-medium pt-1">{error}</p>}
    </div>
  );
};
export default InputBox;
