import Rollbar from 'rollbar';

export const getRollBarEnvironment = () => {
  if (process.env.REACT_APP_ENVTYPE === 'PRODUCTION') {
    return 'production';
  } else {
    if (process.env.REACT_APP_ENVNAME === 'dev') {
      return 'development';
    } else if (process.env.REACT_APP_ENVNAME === 'stage') {
      return 'staging';
    } else if (process.env.REACT_APP_ENVNAME === 'prod') {
      return 'pre-production';
    }
  }
};

const rollbar = new Rollbar({
  accessToken: process.env.REACT_APP_ROLL_BAR_ACCESS_TOKEN,
  environment: getRollBarEnvironment(),
});

export default rollbar;
