import { Button, MobileHeader } from '@kindlyhuman/component-library';

import { WelcomeSlogan } from '../../components/common/WelcomeSlogan';
import useAuth from '../../hooks/useAuth';

const PwaRedirectPage = (): JSX.Element => {
  const { logout } = useAuth();

  return (
    <>
      <MobileHeader className="md:hidden" />
      <div className="grid w-screen h-screen grid-cols-1 md:grid-cols-2">
        <WelcomeSlogan className="hidden md:block" />
        <div className="flex flex-col items-center justify-center pb-16 md:pb-0">
          <div className="flex flex-col items-center text-center gap-y-8">
            <p className="md:mb-4">It appears you are a peer trying to login as a member.</p>
            <p>Please click the button below to be redirected to our peer web app.</p>
            <a href={process.env.REACT_APP_LISTENER_LINK}>
              <Button variant="primary">Peer Web App</Button>
            </a>
            <div className="flex flex-col justify-center items-center gap-1 text-sm">
              <p className="text-gray-800 font-normal leading-tight pb-6">
                Would you like to sign up or login as a member?
              </p>
              <Button
                variant="secondary"
                data-testid="login-button"
                className="flex items-center text-primary font-bold leading-tight"
                onClick={logout}
              >
                Logout
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PwaRedirectPage;
