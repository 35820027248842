import { Avatar, AvatarSkeleton, PhoneIcon } from '@kindlyhuman/component-library';
import React from 'react';
import { Experience } from '../../hooks/useExperiences';
import { ExperienceModal } from '../../containers/home/home-components/experience-modal';
import { TagPill } from './tag-pill';
import useAuth from '../../hooks/useAuth';

interface ExperienceTileProps {
  experience: Experience;
  handleScheduleModalOpen: (experience: Experience) => void;
}

export const ExperienceTile: React.FC<ExperienceTileProps> = ({ experience, handleScheduleModalOpen }) => {
  const { user } = useAuth();
  const [experienceModal, setExperienceModal] = React.useState(false);

  const showClientAffiliation = user?.client_id === experience.client_id && experience.client_image_url;
  const showResourceAffiliation = user?.client_id === experience.resource_client_id && experience.resource_image_url;

  const handleScheduleModalOpenOnTile = (experience: Experience) => {
    setExperienceModal(false);
    handleScheduleModalOpen(experience);
  };

  return (
    <>
      <div
        className={`bg-white rounded-[15px] flex flex-col p-3 h-56 w-[336px] shrink-0 cursor-pointer`}
        onClick={() => {
          setExperienceModal(true);
        }}
      >
        <div className="flex flex-col h-24 justify-between">
          <div className="flex justify-between">
            <div className="flex gap-2">
              <Avatar variant="rounderSmall" image={experience.profile_photo_square_file_url} className="m-1" />
              <div className="flex flex-col gap-1">
                <div
                  data-testid={`peer-${experience.name}`}
                  className="text-xl font-textaBlack antialiased leading-normal"
                >
                  {experience.name}
                </div>
                {experience.is_listener && (
                  <div className="flex gap-2">
                    <div className="rounded-full w-5 h-5 bg-[#2ee5da] flex justify-center items-center">
                      <PhoneIcon color="black" width={11} />
                    </div>
                    <div className="text-sm font-texta antialiased leading-normal">Listener</div>
                  </div>
                )}
              </div>
            </div>
            {(showResourceAffiliation || showClientAffiliation) && (
              <div className="flex flex-col items-center">
                <TagPill name={'Certified Peer'} className="m-1.5 w-fit bg-[#ff5d00] text-white" />
                {showResourceAffiliation ? (
                  <img src={experience.resource_image_url} className="w-20" alt="Experience Resource Logo" />
                ) : (
                  <img src={experience.client_image_url} className="w-20" alt="Experience Client Logo" />
                )}
              </div>
            )}
          </div>
          <div className="flex gap-1 p-1 items-end h-24">
            {experience?.tag_names?.slice(0, 2).map((tag, index) => (
              <div key={index} className="bg-[#25008a] rounded-md h-5 flex items-center">
                <div className=" text-center text-white text-[10px] font-thin font-manrope leading-[18px] px-2">
                  {tag}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div
          className={`px-2 pt-3 text-base font-medium font-manrope antialiased leading-5 text-[#666666] line-clamp-4 text-wrap truncate`}
        >
          {experience.admin_summary || experience.excerpt}
        </div>
      </div>
      {experienceModal && (
        <ExperienceModal
          experience={experience}
          open={experienceModal}
          onClose={() => setExperienceModal(false)}
          handleScheduleModalOpen={handleScheduleModalOpenOnTile}
        />
      )}
    </>
  );
};

export const ExperienceTileSkeleton: React.FC = () => {
  return (
    <div className={`bg-gray-300 rounded-[15px] flex flex-col gap-2 animate-pulse p-2 h-56 min-w-[336px]`}>
      <div className="flex gap-2 p-2 h-24">
        <AvatarSkeleton variant="rounderSmall" />
        <div className="flex flex-col py-2 gap-2 w-full">
          <div className="h-6 bg-gray-400 rounded w-3/4" />
          <div className="h-4 bg-gray-400 rounded w-1/2" />
        </div>
      </div>
      <div className="flex flex-col p-2 gap-2 w-full">
        <div className="h-4 bg-gray-400 rounded" />
        <div className="h-4 bg-gray-400 rounded" />
        <div className="h-4 bg-gray-400 rounded" />
        <div className="h-4 bg-gray-400 rounded" />
      </div>
    </div>
  );
};
