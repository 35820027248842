import { Button } from '@kindlyhuman/component-library';

import { ChangePasswordForm } from '../../../common/change_password_form';

import { TabHeader } from '../tab_header';

export const ChangePasswordTab = () => {
  return (
    <>
      <TabHeader header="Change password" />
      <ChangePasswordForm
        className="space-y-3 bg-white px-4 md:px-5 py-5 md:rounded-lg md:border border-[#E6E6E6]"
        bottomActionButton={({ isDirty, isSubmitting }) => (
          <div className="mt-4 flex justify-end">
            <Button variant="primary" type="submit" disabled={!isDirty || isSubmitting} loading={isSubmitting}>
              Change password
            </Button>
          </div>
        )}
      />
    </>
  );
};
