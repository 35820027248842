import { Route, Redirect, Switch } from 'react-router-dom';

// Page Imports
import { VerificationPage, ResetPassword, WelcomePage, SignupPage, LoginPage } from '../containers';

// These could be added to the containers index
import AuthenticatePage from '../containers/authenticate/authenticate';
import { ROUTE_PATH } from './route-paths';
import { GroupIdRouter } from './group-id-router';

const unauthenticatedRoutes = [
  {
    path: ROUTE_PATH.LOGIN,
    component: LoginPage,
  },
  {
    path: ROUTE_PATH.SIGNUP,
    component: SignupPage,
  },
  {
    path: ROUTE_PATH.VERIFICATION,
    component: VerificationPage,
  },
  {
    path: ROUTE_PATH.AUTHENTICATE,
    component: AuthenticatePage,
  },
];

export const publicRoutes = [
  {
    path: ROUTE_PATH.LANDING_PAGE,
    component: WelcomePage,
  },
  {
    path: ROUTE_PATH.WELCOME_PAGE,
    component: WelcomePage,
  },
  {
    path: ROUTE_PATH.RESET_PASSWORD,
    component: ResetPassword,
  },
];

export const UnauthenticatedRouter = () => {
  return (
    <Switch>
      {publicRoutes.map(({ path, component }) => (
        <Route exact path={path} component={component} />
      ))}
      {unauthenticatedRoutes.map(({ path, component }) => (
        <Route exact path={path} component={component} />
      ))}
      <Route exact path="/:groupId" component={GroupIdRouter} />
      <Route exact path="/welcome/:groupId" component={GroupIdRouter} />
      <Route exact path="*" render={() => <Redirect to={ROUTE_PATH.WELCOME_PAGE} />} />
    </Switch>
  );
};
