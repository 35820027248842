import React, { Dispatch, useState } from 'react';
import { useHistory } from 'react-router';
import { Peer, useAddFavoritePeerWithParams, useRemoveFavoritePeerWithParams } from '../../hooks/usePeers';
import { ROUTE_PATH } from '../../routes/route-paths';
import { Avatar, AvatarSkeleton, Button, HeartIcon, PhoneIcon } from '@kindlyhuman/component-library';
import { Clock } from '../../assets';
import { FollowPill } from './follow-pill';
import { Link } from 'react-router-dom';
import { useIsEverybodyAvailable } from '../../hooks/useCalls';
import { TagPill } from './tag-pill';
import useAuth from '../../hooks/useAuth';

interface ConnectNowPeerCardProps {
  peer: Peer;
  setScheduleModal: Dispatch<React.SetStateAction<number | undefined>>;
  setScheduleAvailableNow: Dispatch<React.SetStateAction<boolean>>;
}

export const ConnectNowPeerCard: React.FC<ConnectNowPeerCardProps> = ({
  peer,
  setScheduleModal,
  setScheduleAvailableNow,
}) => {
  const history = useHistory();
  const { user } = useAuth();
  const isEverybodyAvailable = useIsEverybodyAvailable(peer.is_available);
  const removeFavoritePeer = useRemoveFavoritePeerWithParams();
  const addFavoritePeer = useAddFavoritePeerWithParams();

  const showClientAffiliation = user?.client_id === peer.client_id && peer.client_image_url;
  const showResourceAffiliation = user?.client_id === peer.resource_client_id && peer.resource_image_url;

  const handleFollowing = async (listenerRoleId: number, isFollowing: boolean) => {
    const callerRoleId = user?.caller_role_id!;

    if (isFollowing) {
      await removeFavoritePeer.mutateAsync({ listenerRoleId, callerRoleId });
    } else {
      await addFavoritePeer.mutateAsync({ listenerRoleId, callerRoleId });
    }
  };
  return (
    <div
      data-testId={`Peer #${peer.listener_role_id}`}
      className={`w-90 h-90 bg-[#e5efff] rounded-[10px] flex flex-col gap-6 p-5 select-none`}
    >
      <div className="flex gap-6">
        <Link to={`${ROUTE_PATH.PEER_DETAILS}?listenerId=${peer.listener_role_id}`}>
          <Avatar variant="rounderLarge" image={peer.profile_photo_url_square} className="cursor-pointer" />
        </Link>
        <div className="flex flex-col justify-between">
          <div className="flex flex-col gap-1">
            <div
              data-testid={`peer-${peer.display_name}`}
              className="text-xl font-extrabold font-manrope text-[#240089] hover:cursor-pointer"
              onClick={() => {
                history.push(`${ROUTE_PATH.PEER_DETAILS}?listenerId=${peer.listener_role_id}`);
              }}
            >
              {peer.display_name}
            </div>
            {peer.is_listener && (
              <div className="flex gap-2">
                <div className="rounded-full w-6 h-6 bg-[#2ee5da] flex justify-center items-center">
                  <PhoneIcon color="black" width={14} />
                </div>
                <div className="text-sm font-texta antialiased leading-normal">Listener</div>
              </div>
            )}
            {peer.is_available && peer.is_listener && (
              <div className="flex gap-2">
                <div className="rounded-full w-6 h-6 bg-[#2ee5da] flex justify-center items-center">
                  <img src={Clock} alt="clock" />
                </div>
                <div className="text-sm font-texta antialiased leading-normal">Available Now</div>
              </div>
            )}
          </div>
          <FollowPill
            isFollowing={peer.is_member_following}
            handleFollowing={() => handleFollowing(peer.listener_role_id, peer.is_member_following)}
          />
        </div>
      </div>
      <div className="flex flex-col">
        <div className="flex flex-col gap-2">
          <div className="flex gap-2 flex-wrap overflow-hidden h-12">
            {peer.tags.map((tag) => (
              <div
                key={tag.id}
                className="text-nowrap h-5 text-xs font-texta antialiased leading-normal bg-white px-2 rounded-md"
              >
                {tag.name}
              </div>
            ))}
          </div>
        </div>
        <div className="flex justify-between">
          {peer.is_listener ? (
            <Button
              variant="secondary"
              className="text-[#2ee5da] h-12 mt-8 bg-[#081d40] hover:bg-[#081d40] hover:bg-opacity-70"
              onClick={() => {
                setScheduleModal(peer.listener_role_id);
                setScheduleAvailableNow(peer.is_available);
              }}
            >
              {isEverybodyAvailable ? 'Connect Now' : 'Schedule a Call'}
            </Button>
          ) : (
            <div className="h-12"></div>
          )}
          {(showResourceAffiliation || showClientAffiliation) && (
            <div className="flex flex-col items-center mt-5">
              <TagPill name={'Certified Peer'} className="m-1.5 w-fit bg-[#ff5d00] text-white" />
              {showResourceAffiliation ? (
                <img src={peer.resource_image_url} className="w-20" alt="Experience Resource Logo" />
              ) : (
                <img src={peer.client_image_url} className="w-20" alt="Experience Client Logo" />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export const ConnectNowPeerCardSkeleton: React.FC = () => {
  return (
    <div className="h-full w-90 bg-[#e5efff] rounded-[10px] flex flex-col gap-6 p-5 animate-pulse">
      <div className="flex gap-6">
        <AvatarSkeleton variant="rounderLarge" />

        <div className="flex flex-col justify-between w-full">
          <div className="flex flex-col gap-1">
            <div className="text-xl font-extrabold font-manrope text-[#240089] bg-gray-300 h-6 w-32"></div>
            <div className="flex gap-2 mt-2 items-center">
              <div className="rounded-full w-6 h-6 bg-gray-300"></div>
              <div className="text-sm bg-gray-300 h-4 w-20"></div>
            </div>
            <div className="flex gap-2 mt-2 items-center">
              <div className="rounded-full w-6 h-6 bg-gray-300"></div>
              <div className="text-sm bg-gray-300 h-4 w-24"></div>
            </div>
          </div>
          <div className="w-24 rounded-full flex px-2 items-center gap-2 text-sm bg-gray-300 h-8"></div>
        </div>
      </div>
      <div className="flex flex-col gap-8">
        <div className="flex flex-col gap-2">
          <div className="flex gap-2 flex-wrap overflow-hidden h-12">
            <div className="text-nowrap h-5 text-xs bg-gray-300 px-2 rounded-md w-12"></div>
            <div className="text-nowrap h-5 text-xs bg-gray-300 px-2 rounded-md w-12"></div>
            <div className="text-nowrap h-5 text-xs bg-gray-300 px-2 rounded-md w-12"></div>
          </div>
        </div>
        <div className="flex">
          <div className="bg-gray-300 rounded-full py-2 px-4 w-42 h-12"></div>
        </div>
      </div>
    </div>
  );
};
