import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { INVALID_DATE } from '.';
import { DatePicker, FormError, FormItemName } from '@kindlyhuman/component-library';

const DOB: React.FC = () => {
  const {
    formState: { errors },
    control,
    setValue,
  } = useFormContext();

  return (
    <div className="space-y-6">
      <p
        className="
          text-gray-900 text-2xl font-manrope
          md:text-center md:text-3xl
        "
      >
        <span className="font-bold">How old</span> are you?
      </p>
      <div className="space-y-1.5">
        <FormItemName name="DATE OF BIRTH" isRequired />
        <Controller
          name="date_of_birth"
          control={control}
          defaultValue={''}
          render={({ field: { onChange, value } }) => (
            <DatePicker
              value={value?.replaceAll('-', '/')}
              onChange={(value: any | null) => {
                if (value) {
                  const date =
                    +value?.format('yyyy') < 1000 || !value?.year()
                      ? INVALID_DATE
                      : value?.format('MM-DD-yyyy').toString();
                  return onChange(date);
                } else {
                  setValue('date_of_birth', '', {
                    shouldDirty: true,
                  });
                }
              }}
              onOpen={() => {
                if (value === '') {
                  return onChange('01-01-1990');
                }
              }}
            />
          )}
        />
        {errors?.date_of_birth?.message && <FormError text={errors?.date_of_birth?.message as string} />}
        <p className=" text-gray-900 text-sm not-italic font-normal leading-5 md:text-neutral-700">
          You must be 18 years or older to use our service. Your age is used to help match you with relevant peers.
        </p>
      </div>
    </div>
  );
};

export default DOB;
