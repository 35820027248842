import { useState, useRef, useEffect } from 'react';
import { useListenerAudioPlayMutation, useListenerAudioPlaybackMutation } from '../../hooks/useListenerAudio';
import { useRateExperience } from '../../hooks/useExperiences';
import { Experience } from '../../hooks/useExperiences';
import { PeerCardExperience } from '../../containers/peer-details/components/experiences-section';
import { analytics, EventTypes } from '@kindlyhuman/component-library';

export const useAudioPlayer = (experience: Experience | PeerCardExperience) => {
  const audioRef = useRef<HTMLAudioElement>(null);
  const playMutation = useListenerAudioPlayMutation();
  const playBackMutation = useListenerAudioPlaybackMutation();
  const rateExperienceMutation = useRateExperience();
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [paused, setPaused] = useState<boolean>(true);
  const [percent, setPercent] = useState<number>(0);
  const [currentPlaybackId, setCurrentPlaybackId] = useState<number>();
  const [experienceRating, setExperienceRating] = useState<number>(experience.rating);

  const audioStopAll = () => {
    document.querySelectorAll('audio').forEach((el) => {
      el.pause();
      el.currentTime = 0;
    });
  };

  const onPlay = () => {
    if (isPlaying) return;
    setIsPlaying(true);

    if (percent === 0) {
      /*
      We only want to make a playback record and google analytics event if the user is starting from the beginning
      aka. if they press pause then play again in the same session, don't make new records.
      */
      analytics.trackEvent(EventTypes.PLAY_EXPERIENCE, experience.id);
      playMutation.mutate(
        {
          audioId: experience.id,
          listenerId: experience.listener_role_id,
        },
        {
          onSuccess: (listenerAudioPlayback) => {
            setCurrentPlaybackId(listenerAudioPlayback.id);
          },
        },
      );
    }
    if (audioRef.current) {
      audioRef.current.currentTime = (percent / 100) * audioRef.current.duration;
      audioRef.current.play();
    }
  };

  const onPause = () => {
    audioRef.current?.pause();
    setIsPlaying(false);
    setPaused(true);
  };

  const onTimeUpdate = (e: React.SyntheticEvent<HTMLAudioElement>) => {
    if (e.currentTarget.currentTime >= e.currentTarget.duration) {
      setPaused(true);
      setIsPlaying(false);
      e.currentTarget.currentTime = 0;
      setCurrentPlaybackId(0);
    }

    if (!currentPlaybackId) {
      console.error('currentPlaybackId is not set for experience id:', experience.id);
      return;
    }

    const percent = Math.round((e.currentTarget.currentTime / experience.duration) * 100);
    if (percent !== 0 && percent % 10 === 0) {
      playBackMutation.mutate({
        audioId: currentPlaybackId,
        percentCompleted: percent,
      });
    }
  };

  const handleRatingClick = (newRating: number) => {
    if (experienceRating === newRating) newRating = 0;

    setExperienceRating(newRating);

    rateExperienceMutation.mutate({
      audioId: experience.id,
      listenerId: experience.listener_role_id,
      rating: newRating,
    });
  };

  useEffect(() => {
    if (!audioRef.current) {
      return;
    }

    const node = audioRef.current;

    const timingEvent = (event: Event) => {
      const element = event.target as HTMLAudioElement;
      setPercent(parseFloat(((element.currentTime / element.duration) * 100).toPrecision(3)));
    };

    node.addEventListener('timeupdate', timingEvent);

    return () => {
      node.removeEventListener('timeupdate', timingEvent);
    };
  }, []);

  useEffect(() => {
    if (!audioRef.current) {
      return;
    }
    setPaused(audioRef.current?.paused);
  }, [audioRef.current?.paused]);

  return {
    audioRef,
    paused,
    percent,
    experienceRating,
    audioStopAll,
    onPlay,
    onPause,
    onTimeUpdate,
    handleRatingClick,
  };
};
