import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { axiosGet, axiosPost } from '../api/axios-handler';

export interface SetupPaymentIntentResponse {
  intent_client_secret: string;
  publishable_key: string;
}

const getStripeKeys = async (userCallerRoleId: number): Promise<SetupPaymentIntentResponse> => {
  return await axiosPost(`/users/stripe_setup_intent?caller_role_id=${userCallerRoleId}`, {}).then(
    (response: AxiosResponse<SetupPaymentIntentResponse>) => response.data,
  );
};

export const useStripeKeys = (userCallerRoleId: number | undefined) => {
  const query = useQuery<SetupPaymentIntentResponse>(['stripeKeys', userCallerRoleId], () =>
    userCallerRoleId
      ? getStripeKeys(userCallerRoleId)
      : Promise.resolve({
          intent_client_secret: '',
          publishable_key: '',
        }),
  );

  return query;
};

export type cardBrandType =
  | 'amex'
  | 'diners'
  | 'discover'
  | 'eftpos_au'
  | 'jcb'
  | 'mastercard'
  | 'unionpay'
  | 'visa'
  | 'unknown';

export interface MoneyReportResponse {
  payment_method?: {
    card: {
      last4: string;
      brand: cardBrandType;
    };
  };
}

const getPaymentMethod = async (userId: number): Promise<MoneyReportResponse> => {
  return await axiosGet(`/users/${userId}/money_report`, {}).then(
    (response: AxiosResponse<MoneyReportResponse>) => response.data,
  );
};

export const usePaymentMethod = (userId: number | undefined) => {
  const queryClient = useQueryClient();

  const query = useQuery<MoneyReportResponse>(['paymentMethod', userId], () =>
    userId ? getPaymentMethod(userId) : Promise.resolve({}),
  );

  const removePaymentMethod = useMutation(
    (userId: number) => {
      return axiosPost(`/users/${userId}/stripe_remove_payment_method`, {});
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['me']);
        queryClient.invalidateQueries(['paymentMethod', userId]);
        queryClient.invalidateQueries(['stripeKeys']);
      },
    },
  );

  return {
    ...query,
    removePaymentMethod,
  };
};
