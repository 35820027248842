import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import { DropdownMenuItem } from '@kindlyhuman/component-library';

import { axiosDelete, axiosGet, axiosPost } from '../api/axios-handler';

export type Dependent = {
  display_name: string;
  email_address: string;
  first_name: string;
  id: number;
  invite_status: 'pending' | 'completed';
  last_name: string;
  mobile_phone: string;
};

export type DependentCardProps = {
  displayName: string;
  emailAddress: string;
  phoneNumber: string;
  pending: boolean;
  menuItems: DropdownMenuItem[];
};

const getInviteDependents = async (userId: number | undefined): Promise<Dependent[]> => {
  return await axiosGet(`/users/${userId ?? -1}/dependents`, {}).then(
    (response: AxiosResponse<Dependent[]>) => response.data,
  );
};

export const useInviteDependents = (userId: number | undefined) => {
  const queryClient = useQueryClient();

  const query = useQuery<Dependent[]>(['inviteDependents', userId], () => getInviteDependents(userId));

  const resendInvite = useMutation(
    (dependentId: number) => {
      return axiosPost(`/users/${userId}/dependents/${dependentId}/resend_invite`, null);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['inviteDependents', userId]);
      },
    },
  );

  const removeDependent = useMutation(
    (dependentId: number) => {
      return axiosDelete(`/users/${userId}/dependents/${dependentId}`, undefined);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['inviteDependents', userId]);
      },
    },
  );

  const saveDependent = useMutation(
    ({
      first_name,
      last_name,
      mobile_phone,
      email_address,
    }: Pick<Dependent, 'first_name' | 'last_name' | 'mobile_phone' | 'email_address'>) => {
      return axiosPost(`/users/${userId}/dependents`, {
        first_name,
        last_name,
        mobile_phone,
        email_address,
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['inviteDependents', userId]);
      },
    },
  );

  return {
    ...query,
    resendInvite,
    removeDependent,
    saveDependent,
  };
};
