import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, SpinnerIcon } from '@kindlyhuman/component-library';
import { usePaymentMethod } from '../../hooks/useStripe';
import { ROUTE_PATH } from '../../routes/route-paths';
import { MobileMenuHeader, DesktopMenuHeader } from '../../components/MenuHeader';
import Toast from '../../components/common/PopUpMessage';
import { PoweredByStripe } from '../../components/common/payment/powered_by_stripe';
import { AddPaymentForm } from '../../components/common/payment/add_payment_form';
import { PaymentMethod } from '../../components/common/payment/payment_method';
import { UserPlan } from '../../components/common/user_plan';
import useAuth from '../../hooks/useAuth';

export const PaymentMethodPage = () => {
  const navigate = useHistory();
  const { user, isLoading: userIsLoading } = useAuth();
  const { data: { payment_method } = {}, isLoading: isPaymentMethodLoading } = usePaymentMethod(user?.id);

  const [showUpdateCardForm, setShowUpdateCardForm] = useState<boolean>(false);

  if (userIsLoading || isPaymentMethodLoading) {
    return (
      <div className="w-full h-[100vh] flex items-center justify-center">
        <SpinnerIcon />
      </div>
    );
  }

  return (
    <>
      <DesktopMenuHeader />
      <MobileMenuHeader onBack={() => navigate.goBack()} />
      <div className="bg-neutral-200 bg-opacity-30 min-h-screen">
        <div className="py-5 px-5 border-b-2">
          <div className="text-gray-800 text-2xl leading-loose font-bold">Payment method</div>
          <div className="text-gray-800 text-base font-normal leading-normal">
            Add a payment method to cover charges from paid minutes with a Listener
          </div>
        </div>
        <div className="bg-white">{!!user && <UserPlan user={user} className="p-4" />}</div>
        <div className="py-4 border-t-2">
          <div className="bg-white text-gray-800 text-xl leading-loose font-bold p-5">Card details</div>

          {!payment_method?.card || showUpdateCardForm ? (
            <AddPaymentForm
              className="bg-white px-5 pb-4"
              bottomActionButton={({ isSubmitting }) => (
                <div className="flex flex-col items-center justify-center mt-4 gap-7">
                  <PoweredByStripe />
                  <Button variant="primary" type="submit" disabled={isSubmitting} loading={isSubmitting}>
                    Update details
                  </Button>
                </div>
              )}
              onSuccessAddPayment={() => {
                setShowUpdateCardForm(false);
                Toast.success('You have successfully updated your payment details', 'Payment details updated!');
              }}
              onErrorAddPayment={() => {
                Toast.error(
                  'Your payment method has failed to process.  Confirm your payment info is correct and try again.',
                  'Payment Failed',
                );
              }}
            />
          ) : (
            <>
              <div className="px-5 bg-white pb-4">
                <PaymentMethod
                  className="p-3 rounded-lg border border-neutral-200"
                  onUpdateButtonClick={() => setShowUpdateCardForm(true)}
                  onSuccessRemovePayment={() => {
                    Toast.success('You have successfully removed your payment details', 'Payment details updated!');
                  }}
                />
              </div>
              <PoweredByStripe className="justify-center mt-7" />
            </>
          )}
        </div>
      </div>
    </>
  );
};
