import { useHistory } from 'react-router-dom';
import { MobileMenuHeader, DesktopMenuHeader } from '../../../components/MenuHeader';
import { PLUSOnDemandCounseling, PLUSOnDemandCounselingEAP } from '../../../assets';
import { Spinner } from '../../../components/common';
import { ResourceDetailView } from '.././resource-detail';
import { BackgroundStripe } from '../../../components/mwa-3.5-redesign/bg-stripe';
import { ProviderDescription, useProviderDetails } from '../resource-components/useProviderDetails';

export const CrisisPage: React.FC = () => {
  // As of now, the only provider that has a crisis benefits is Recuro
  const navigate = useHistory();
  const {
    clientLogoUrl,
    isLoading,
    partnerResourceConfig,
    phoneNumber,
    providerName,
    providerTypes,
    url,
    urlDescription,
  } = useProviderDetails();

  const hasWorklife = !providerTypes?.includes('crisis_lite');
  const name = hasWorklife ? '+On-Demand Counseling & EAP' : '+On-Demand Counseling';
  const description = (
    <ProviderDescription
      description={
        hasWorklife
          ? 'Your benefit includes 24/7 access to compassionate counseling and resources so you’re always supported when navigating life’s challenges. From day-to-day challenges to a sudden critical event, Care Managers are available 24/7 to help you with personal, family, and work-related concerns. You also have access to 3 free in-person or virtual visits with a licensed behavioral health clinician per need, per year as well referrals to family support, financial planning, and legal resources.'
          : 'Your benefit includes 24/7 access to compassionate counseling and resources so you’re always supported when navigating life’s challenges. From day-to-day challenges to a sudden critical event, Care Managers are available 24/7 to help you with personal, family, and work-related concerns. You also have access to 3 free in-person or virtual visits with a licensed behavioral health clinician per need, per year.'
      }
      onlineAccessText="Click the link above to schedule a visit online."
    />
  );

  if (isLoading) return <Spinner />;

  return (
    <div className="w-full min-h-screen overflow-auto bg-cover bg-neutral-700 bg-opacity-5 pb-20">
      <DesktopMenuHeader />
      <MobileMenuHeader onBack={() => navigate.goBack()} />
      <ResourceDetailView
        name={name}
        providerName={providerName}
        imageUrl={hasWorklife ? PLUSOnDemandCounselingEAP : PLUSOnDemandCounseling}
        ctaButtonText={'Connect Now'}
        url={url}
        urlDescription={urlDescription}
        phoneNumber={phoneNumber}
        // TODO Jason only provided tag names for +Virtual Primary Care, just guessing here
        tagNames={['Primary Care']}
        description={description}
        clientLogoUrl={clientLogoUrl}
        partnerResourceConfig={partnerResourceConfig}
      />
      <BackgroundStripe />
    </div>
  );
};
