import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';
// These could be added to the containers index
import useAuth from '../hooks/useAuth';
import { AuthenticatedRouter } from './authenticated-router';
import { UnauthenticatedRouter } from './unuathenticated-router';
import { Spinner } from '../components/common/spinner';

declare global {
  interface Window {
    branch: any;
  }
}

const Routes: React.FunctionComponent = (): JSX.Element => {
  const { pathname, search } = useLocation();
  const { user, authenticateWithSSOToken, isLoading } = useAuth();

  // this is to login sso users
  const ssoRedirect = new URLSearchParams(search).get('sso_redirect');
  const ssoAuthentication = useCallback(
    (ssoRedirect: string | null) => {
      if (ssoRedirect !== null && authenticateWithSSOToken.data === undefined && authenticateWithSSOToken.isIdle) {
        authenticateWithSSOToken.mutate({ token: ssoRedirect });
      }
    },
    [authenticateWithSSOToken],
  );

  useEffect(() => {
    ssoAuthentication(ssoRedirect);
  }, [ssoRedirect, ssoAuthentication]);

  // this sends you to the top of the window when you change pages
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (isLoading) {
    return <Spinner />;
  }

  return user ? <AuthenticatedRouter /> : <UnauthenticatedRouter />;
};

export default Routes;
