import React from 'react';
import { Spinner } from '../../../components/common';
import { ResourceDetailView } from '../resource-detail';
import { DesktopMenuHeader, MobileMenuHeader } from '../../../components/MenuHeader';
import { useHistory } from 'react-router-dom';
import { PLUSVirtualPrimaryCare, TelemedDetailPng, Walmart } from '../../../assets';
import { BackgroundStripe } from '../../../components/mwa-3.5-redesign/bg-stripe';
import { ProviderDescription, useProviderDetails } from '../resource-components/useProviderDetails';

const Telemed: React.FunctionComponent = (): JSX.Element => {
  const navigate = useHistory();
  const {
    clientLogoUrl,
    isLoading,
    partnerResourceConfig,
    phoneNumber,
    providerName,
    ssoLinkLoading,
    url,
    urlDescription,
  } = useProviderDetails('primary');
  const providerData = telemedProviderData[providerName!];
  if (isLoading) return <Spinner />;

  return (
    <div className="md:bg-neutral-700 md:bg-opacity-5 h-screen md:pb-8 flex flex-col">
      <DesktopMenuHeader />
      <MobileMenuHeader onBack={() => navigate.goBack()} />
      <ResourceDetailView
        name={providerData.name}
        providerName={providerName}
        imageUrl={providerData.image}
        ssoLinkLoading={providerName !== 'recuro' && ssoLinkLoading}
        ctaButtonText={providerData.ctaButtonText}
        ctaButtonDisabled={providerName !== 'recuro' && ssoLinkLoading}
        url={url}
        urlDescription={urlDescription}
        phoneNumber={phoneNumber}
        tagNames={['Virtual Care']}
        description={providerData.description}
        clientLogoUrl={clientLogoUrl}
        partnerResourceConfig={partnerResourceConfig}
      />
      <BackgroundStripe />
    </div>
  );
};

const telemedProviderData = {
  dialcare: {
    name: '+Telemed',
    description: (
      <ProviderDescription description="Through our partnership with Dialcare, you have access to licensed clinical physicians to discuss health issues." />
    ),
    image: TelemedDetailPng,
    ctaButtonText: 'Connect with a Physician',
  },
  me_md: {
    name: '+Telemed',
    description: (
      <ProviderDescription description="Through our partnership with MeMD, you have access to licensed clinical physicians to discuss health issues." />
    ),
    image: TelemedDetailPng,
    ctaButtonText: 'Connect with a Licensed Provider',
  },
  opyn: {
    name: '+Virtual Primary Care',
    description: (
      <ProviderDescription
        description="Your benefit includes virtual access to board-certified primary care physicians to manage routine and on-going health conditions.  You have the ability to choose a dedicated provider who meets you and your family’s needs and preferences."
        commonUses={[
          'Treatment of Common Illnesses',
          'Preventative Care',
          'Lab Tests & Results Interpretation',
          'Routine Screenings',
          'Referrals to Medical Specialists',
          'Chronic Condition Management',
          'And more',
        ]}
        onlineAccessText="Click the link above to schedule a visit online"
      />
    ),
    image: PLUSVirtualPrimaryCare,
    ctaButtonText: 'Schedule Visit Online',
  },
  recuro: {
    name: '+Virtual Primary Care',
    description: (
      <ProviderDescription
        description="Your benefit includes virtual access to board-certified primary care physicians to manage routine and on-going health conditions.  You have the ability to choose a dedicated provider who meets you and your family’s needs and preferences."
        commonUses={[
          'Annual wellness exams',
          'Health risk assessment review',
          'Care plan development',
          'Chronic condition management',
          'And more!',
        ]}
        onlineAccessText="Click the link above to schedule a visit online"
      />
    ),
    image: PLUSVirtualPrimaryCare,
    ctaButtonText: 'Schedule Visit Online',
  },
};

export default Telemed;
