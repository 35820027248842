import { useHistory } from 'react-router-dom';
import { MobileMenuHeader, DesktopMenuHeader } from '../../../components/MenuHeader';
import { PLUSPrescriptionBenefit } from '../../../assets';
import { Spinner } from '../../../components/common';
import { ResourceDetailView } from '../resource-detail';
import { BackgroundStripe } from '../../../components/mwa-3.5-redesign/bg-stripe';
import { ProviderDescription, useProviderDetails } from '../resource-components/useProviderDetails';

export const PrescriptionBenefitPage: React.FC = () => {
  // As of now, the only provider that has a prescription benefit is Recuro
  const navigate = useHistory();
  const { clientLogoUrl, isLoading, partnerResourceConfig, providerName, url, urlDescription } = useProviderDetails();

  const name = '+Prescription Benefit';
  const description = (
    <ProviderDescription
      description="Your benefit includes access to approximately 65 generic urgent care prescriptions at no cost, or for home
      delivery of ongoing medications for treatment of chronic conditions. Pick up your urgent care medications from the
      pharmacy with a digital card which is conveniently located within your member portal, or order ongoing medications
      for chronic conditions by mail from the convenience of your home. Your prescription benefit is accepted at over
      70,000 retail pharmacy locations nationwide, including CVS, Kroger, Rite Aid, Walgreens, and Walmart."
      commonUses={[
        'Albuterol',
        'Amoxicillin',
        'Atorvastatin',
        'Benazepril',
        'Cephalexin',
        'Clindamycin',
        'Fluoxetine',
        'Lisinopril',
        'Metformin',
        'Prednisone',
        'And more',
      ]}
      onlineAccessText="Click the link above to access your Prescription Benefit."
      additionalDisclaimer="Disclaimer: Recuro Prescription Benefit is powered by WalkerRx."
    />
  );

  if (isLoading) return <Spinner />;

  return (
    <div className="w-full min-h-screen overflow-auto bg-cover bg-neutral-700 bg-opacity-5 pb-20">
      <DesktopMenuHeader />
      <MobileMenuHeader onBack={() => navigate.goBack()} />
      <ResourceDetailView
        name={name}
        providerName={providerName}
        imageUrl={PLUSPrescriptionBenefit}
        ctaButtonText={'Access Your Prescription Benefit'}
        url={url}
        urlDescription={urlDescription}
        callButtonText={'Locate a Pharmacy'}
        phoneNumber={providerName === 'recuro' ? '8006993542' : undefined}
        tagNames={['Prescription Medications']}
        description={description}
        clientLogoUrl={clientLogoUrl}
        partnerResourceConfig={partnerResourceConfig}
      />
      <BackgroundStripe />
    </div>
  );
};
